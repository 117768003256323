'use strict'

const self = {
  container : document.querySelector('.overlay__pavilion'),

  init : () => {
    self.setupElements()
    self.registerEvents()
    setTimeout( () => self.container.classList.add('show'), 1000)

    self.container.querySelector('.btn__close').addEventListener('click', () => {
      return self.container.classList.remove('show')
    })

    // self.container.querySelector('.form-wrapper input[type=submit]').addEventListener('click', () => {
    //   setTimeout( () => {
    //     self.container.querySelector('.default-text').classList.add('hide')
    //     self.container.querySelector('.form-wrapper').classList.add('hide')
    //     self.container.querySelector('.form-success').classList.add('show')
    //   }, 1000)
    // })
  },

  setupElements : () => {

  },

  registerEvents : () => {

  }

}

export default self
