'use strict'

const self = {
  container : document.querySelector('.video--play'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.featuredFigure = document.querySelector('.inline-video-player')

    if ( self.featuredFigure ) {
      self.featuredFigureBG = self.featuredFigure.querySelector('.bg-image')
      self.video = self.featuredFigure.querySelector('video')
    }
  },

  registerEvents : () => {
    if ( self.video ) {
      window.addEventListener('mousemove', self.onMouseMove)
    }
  },

  onMouseMove : e => {
    const target = e.target

    if ( target !== self.featuredFigure && target !== self.featuredFigureBG ) {
      if ( !self.container.classList.contains('hidden') ) self.container.classList.add('hidden')
      return false
    }

    if ( self.container.classList.contains('hidden') ) self.container.classList.remove('hidden')
    self.container.style = `top:${e.clientY}px;left:${e.clientX}px;`
  }

}

export default self
