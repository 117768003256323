'use strict'

import Slider from '../classes/slider'
import HomeCover from './homeCover'
import BodyMovin from '../classes/bodymovin'
import buildingAnimation from '../animations/seattle_a-new-dawn_web_v04.json'

const self = {
  container : document.querySelector('.intro-animation'),

  init : () => {
    self.setupElements()
    self.setupAnimation()

    const shouldPlayIntro = document.referrer.search(location.host) === -1
    if ( shouldPlayIntro ) {
      self.startSequence()
    } else {
      self.skipSequence()
    }
  },

  setupElements : () => {
    self.img = self.container.querySelector('img')
    self.animationContainer = self.container.querySelector('.bodymovin-container')
  },

  startSequence : () => {
    self.img.classList.remove('fade')
    setTimeout( () => self.bmAnimation.play(), 2000 )
  },

  onAnimationComplete : () => {
    self.img.classList.add('fade')

    setTimeout( () => self.container.classList.add('fade'), 1700)
    setTimeout( () => self.setupSliders(), 1700)
    setTimeout( () => self.container.classList.remove('show'), 2600)
  },

  setupSliders : () => {
    const sliders = [...document.querySelectorAll('.slider')]

    setTimeout( () => sliders.forEach( slider => new Slider(slider, {
      rotate : slider.getAttribute("data-rotate") || false,
      intervalDuration : 2000
    })), 500)
  },

  skipSequence : () => {
    self.container.classList.add('fade')
    self.setupSliders()
    setTimeout( () => self.container.classList.remove('show'), 2000)
  },

  setupAnimation : () => self.bmAnimation = new BodyMovin({
    elm : self.animationContainer,
    data : buildingAnimation,
    onComplete : self.onAnimationComplete
  })
}

export default self
