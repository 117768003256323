'use strict'

import Slider from '../classes/slider'

const self = {
  containers : [...document.querySelectorAll('.slider')],

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    //const isHomepage = document.querySelector('main.home')
    //if ( isHomepage ) return false

    self.containers.forEach( slider => new Slider(slider, {
      rotate : slider.getAttribute("data-rotate") || false,
      intervalDuration : 6000
    }) )

  },

  registerEvents : () => {

  }

}

export default self
