
export default class PanoViewer {

  constructor (args) {
    this.el = args.el

    var settings = {
      dampingFactor: 0.93,
      historySize: 5
    }

    var pointerEventToXY = function(e) {
      var out = {
        x: e.pageX,
        y: e.pageY
      }

      return out
    }


    // FIXME: need to convert this but need function isolated to be able to remove
    //$(this).off('mousedown touchstart mousemove touchmove mouseleave mouseup touchend touchcancel');

    var $container;
		var sx;
		var dx = 0;
		var armed;
		var offset = 0;
		var tick;
		var prev;
		var h = [];

		$container = this.el;



    //
    // --------------------------------
    const actionStart = e => {
      let inheritOffset = parseInt($container.style['background-position'].replace(/(px)\s(.*)/g, ''));

			if (Math.round(offset) !== inheritOffset) {
				offset = inheritOffset
			}

			sx = pointerEventToXY(e).x - offset;
			armed = true;
			e.preventDefault();
    }
    this.el.addEventListener('mousedown', actionStart)
    this.el.addEventListener('touchstart', actionStart)


    //
    // --------------------------------
    const actionMove = e => {
      var px;
			if (armed) {
				px = pointerEventToXY(e).x;
				if (prev === undefined) {
					prev = px;
				}
				offset = px - sx;
				if (h.length > settings.historySize) {
					h.shift();
				}
				h.push(prev - px);

				$container.style['background-position'] = `${offset}px center`

				prev = px;
			}
    }
    this.el.addEventListener('mousemove', actionMove)
    this.el.addEventListener('touchmove', actionMove)



    const actionEnd = () => {
      if (armed) {
        var i, len = h.length;
        var v = h[len - 1];
        for (i = 0; i < len; i++) {
          v = (v * len + (h[i])) / (len + 1);
        }
        dx = v;
      }
      armed = false;
    }

    this.el.addEventListener('mouseleave', actionEnd);
    this.el.addEventListener('mouseup', actionEnd);
    this.el.addEventListener('touchend', actionEnd);
    this.el.addEventListener('touchcancel', actionEnd);

    tick = function() {
			if (!armed && dx) {
				dx *= settings.dampingFactor;
				offset -= dx;
				$container.style['background-position'] = `${offset}px center`
				if (Math.abs(dx) < 0.001) {
					dx = 0;
				}
			}
		};

    setInterval(tick, 16);



  } // constructor

}
