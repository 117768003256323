'use strict';

// Dependancies
import "babel-polyfill"

// Classes
import Reveal from './classes/reveal'

// Modules
import Header from './modules/header'
import HeaderTransparent from './modules/headerTransparent'
import HomeGallery from './modules/homeGallery'
import Tooltip from './modules/tooltip'
import HomeCover from './modules/homeCover'
import Tabs from './modules/tabs'
import InlineVideoPlayer from './modules/inlineVideoPlayer'
import Footer from './modules/footer'
import PopupMsg from './modules/popupMsg'
import NavDropdown from './modules/navDropdown'
import GalleryOverlay from './modules/galleryOverlay'
import SalesforceFrom from './modules/salesforceForm'
import IntroAnimation from './modules/introAnimation'
import Sliders from './modules/sliders'
import LoopSlider from './modules/loopSlider'
import ScrollNav from './modules/scrollNav'
import InlineVideo from './modules/inlineVideo'
import Floorplans from './modules/floorplans'
import SeattleMap from './modules/seattleMap'
import McAjaxSubmit from './modules/mcAjaxSubmit'
import MouseStalker from './modules/mouseStalker'
import Cny2019 from './modules/cny2019'
import OverlayLNY from './modules/overlayLNY'
import OverlayPavilion from './modules/overlayPavilion'
import IndexViews from './modules/indexViews'
import HomeAutoPan from './modules/homeAutoPan'
import ArchitectureAutoPan from './modules/architectureAutoPan'
import OfficeAutoPan from './modules/officeAutoPan'
import SeattleAutoPan from './modules/seattleAutoPan'
import TeamAutoPan from './modules/teamAutoPan'

// Conditional Functionality
const m = [
  { o : Header,            i : Header.container },
  { o : HeaderTransparent, i : HeaderTransparent.container },
  { o : HomeGallery,       i : HomeGallery.container },
  { o : Tooltip,           i : Tooltip.container },
  { o : HomeCover,         i : HomeCover.container },
  { o : Tabs,              i : Tabs.container },
  { o : InlineVideoPlayer, i : InlineVideoPlayer.container },
  { o : Footer,            i : Footer.container },
  { o : PopupMsg,          i : PopupMsg.container },
  { o : NavDropdown,       i : NavDropdown.container },
  { o : GalleryOverlay,    i : GalleryOverlay.container },
  { o : SalesforceFrom,    i : SalesforceFrom.container },
  { o : IntroAnimation,    i : IntroAnimation.container },
  { o : Sliders,           i : Sliders.containers[0] },
  { o : LoopSlider,        i : LoopSlider.container },
  { o : ScrollNav,         i : ScrollNav.container },
  { o : InlineVideo,       i : InlineVideo.containers[0] },
  { o : Floorplans,        i : Floorplans.elms.container },
  { o : SeattleMap,        i : SeattleMap.container },
  { o : McAjaxSubmit,      i : McAjaxSubmit.container },
  { o : MouseStalker,      i : MouseStalker.container },
  { o : Cny2019,           i : Cny2019.container },
  { o : OverlayLNY,        i : OverlayLNY.container },
  { o : OverlayPavilion,   i : OverlayPavilion.container },
  { o : IndexViews,        i : IndexViews.container },
  { o : HomeAutoPan,       i : HomeAutoPan.container },
  { o : ArchitectureAutoPan,i : ArchitectureAutoPan.container },
  { o : OfficeAutoPan,     i : OfficeAutoPan.container },
  { o : SeattleAutoPan,    i : SeattleAutoPan.container },
  { o : TeamAutoPan,       i : TeamAutoPan.container }
].map( a => (a.i) ? a.o.init() : null );

// Reveal
const revealElms = [...document.querySelectorAll('[data-reveal]')]
const reveal = ( revealElms[0] ) ? new Reveal({ elements : revealElms }) : null
