'use strict'

const self = {
  container : document.querySelector('header'),
  lastScrollPosition : 0,

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.hamburger = document.querySelector('.hamburger')
    self.navGlobal = document.querySelector('.nav--global')

    //self.artistLink = self.navGlobal.querySelector('a[href="/#artists"]')
    self.registerLink = self.container.querySelector('a[href="/register-contact/#register"]')
  },

  registerEvents : () => {
    self.hamburger.addEventListener('click', self.toggleMenu)
    //self.artistLink.addEventListener('click', self.handleClick)
    self.registerLink.addEventListener('click', self.handleClick)
  },

  toggleMenu : e => document.documentElement.classList.toggle('menu--open'),

  handleClick : e => {
    if ( document.body.classList.contains('home') && document.documentElement.classList.contains('menu--open') ) {
      document.querySelector('.hamburger').click()
    }
  }

}

export default self
