'use strict'

const self = {
  container : document.querySelector('.tabs--container'),

  init : () => {
    self.setupElements()
    self.registerEvents()
    self.searchForVideoEmbeds()
  },

  setupElements : () => {
    self.tabButtons = [...document.querySelectorAll('.nav--tabs button')]
  },

  registerEvents : () => {
    self.tabButtons.forEach( button => button.addEventListener('click', self.changeTab) )
  },

  searchForVideoEmbeds : () => {
    const iframes = document.querySelectorAll('.tabs--tab p iframe')

    iframes.forEach( iframe => iframe.parentNode.classList.add('tab__video-container') )

    console.log( iframes )
  },

  changeTab : e => {
    const button = e.currentTarget
    const activeButton = button.parentNode.querySelector('button.active')
    const rel = button.getAttribute('rel')
    const activeTab = document.querySelector('.tabs--tab.show')
    const newTab = document.querySelector(`.tabs--tab[rel="${rel}"]`)

    activeButton.classList.remove('active')
    button.classList.add('active')

    activeTab.classList.remove('show')
    newTab.classList.add('show')

  }

}

export default self
