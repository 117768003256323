module.exports=[
  {
    "id": 1,
    "Unit #": 801,
    "Floor": 8,
    "Unit": 1,
    "Plan Type": "B2",
    "Size (Interior)": 392,
    "Size (Exterior)": 74,
    "Size (Total)": 466,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 2,
    "Unit #": 802,
    "Floor": 8,
    "Unit": 2,
    "Plan Type": "D2",
    "Size (Interior)": 649,
    "Size (Exterior)": 295,
    "Size (Total)": 944,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NWN",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 3,
    "Unit #": 803,
    "Floor": 8,
    "Unit": 3,
    "Plan Type": "A",
    "Size (Interior)": 394,
    "Size (Exterior)": 64,
    "Size (Total)": 458,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 4,
    "Unit #": 804,
    "Floor": 8,
    "Unit": 4,
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 5,
    "Unit #": 805,
    "Floor": 8,
    "Unit": 5,
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 6,
    "Unit #": 806,
    "Floor": 8,
    "Unit": 6,
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 7,
    "Unit #": 807,
    "Floor": 8,
    "Unit": 7,
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 8,
    "Unit #": 808,
    "Floor": 8,
    "Unit": 8,
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 9,
    "Unit #": 809,
    "Floor": 8,
    "Unit": 9,
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 10,
    "Unit #": 810,
    "Floor": 8,
    "Unit": 10,
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 11,
    "Unit #": 811,
    "Floor": 8,
    "Unit": 11,
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor9"
  },
  {
    "id": 12,
    "Unit #": 812,
    "Floor": 8,
    "Unit": 12,
    "Plan Type": "G2",
    "Size (Interior)": 903,
    "Size (Exterior)": 339,
    "Size (Total)": 1242,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail and Concrete Upstand with Glass Guard",
    "View Img": "floor9"
  },
  {
    "id": 13,
    "Unit #": 813,
    "Floor": 8,
    "Unit": 13,
    "Plan Type": "B2",
    "Size (Interior)": 392,
    "Size (Exterior)": 74,
    "Size (Total)": 466,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 14,
    "Unit #": 901,
    "Floor": 9,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 15,
    "Unit #": 902,
    "Floor": 9,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 16,
    "Unit #": 903,
    "Floor": 9,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 17,
    "Unit #": 904,
    "Floor": 9,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 18,
    "Unit #": 905,
    "Floor": 9,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 19,
    "Unit #": 906,
    "Floor": 9,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor9"
  },
  {
    "id": 20,
    "Unit #": 907,
    "Floor": 9,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 21,
    "Unit #": 908,
    "Floor": 9,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 22,
    "Unit #": 909,
    "Floor": 9,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 23,
    "Unit #": 910,
    "Floor": 9,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor9"
  },
  {
    "id": 24,
    "Unit #": 911,
    "Floor": 9,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "WSW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor9"
  },
  {
    "id": 25,
    "Unit #": 912,
    "Floor": 9,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor9"
  },
  {
    "id": 26,
    "Unit #": 913,
    "Floor": 9,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor9"
  },
  {
    "id": 27,
    "Unit #": 1001,
    "Floor": 10,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 28,
    "Unit #": 1002,
    "Floor": 10,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NWN",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 29,
    "Unit #": 1003,
    "Floor": 10,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 30,
    "Unit #": 1004,
    "Floor": 10,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 31,
    "Unit #": 1005,
    "Floor": 10,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 32,
    "Unit #": 1006,
    "Floor": 10,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor12"
  },
  {
    "id": 33,
    "Unit #": 1007,
    "Floor": 10,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 34,
    "Unit #": 1008,
    "Floor": 10,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 35,
    "Unit #": 1009,
    "Floor": 10,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 36,
    "Unit #": 1010,
    "Floor": 10,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 37,
    "Unit #": 1011,
    "Floor": 10,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor12"
  },
  {
    "id": 38,
    "Unit #": 1012,
    "Floor": 10,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor12"
  },
  {
    "id": 39,
    "Unit #": 1013,
    "Floor": 10,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 40,
    "Unit #": 1101,
    "Floor": 11,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 41,
    "Unit #": 1102,
    "Floor": 11,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NWN",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 42,
    "Unit #": 1103,
    "Floor": 11,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 43,
    "Unit #": 1104,
    "Floor": 11,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 44,
    "Unit #": 1105,
    "Floor": 11,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 45,
    "Unit #": 1106,
    "Floor": 11,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor12"
  },
  {
    "id": 46,
    "Unit #": 1107,
    "Floor": 11,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 47,
    "Unit #": 1108,
    "Floor": 11,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 48,
    "Unit #": 1109,
    "Floor": 11,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 49,
    "Unit #": 1110,
    "Floor": 11,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 50,
    "Unit #": 1111,
    "Floor": 11,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor12"
  },
  {
    "id": 51,
    "Unit #": 1112,
    "Floor": 11,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor12"
  },
  {
    "id": 52,
    "Unit #": 1113,
    "Floor": 11,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 53,
    "Unit #": 1201,
    "Floor": 12,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 54,
    "Unit #": 1202,
    "Floor": 12,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NWN",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 55,
    "Unit #": 1203,
    "Floor": 12,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 56,
    "Unit #": 1204,
    "Floor": 12,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 57,
    "Unit #": 1205,
    "Floor": 12,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 58,
    "Unit #": 1206,
    "Floor": 12,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor12"
  },
  {
    "id": 59,
    "Unit #": 1207,
    "Floor": 12,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 60,
    "Unit #": 1208,
    "Floor": 12,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 61,
    "Unit #": 1209,
    "Floor": 12,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 62,
    "Unit #": 1210,
    "Floor": 12,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor12"
  },
  {
    "id": 63,
    "Unit #": 1211,
    "Floor": 12,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor12"
  },
  {
    "id": 64,
    "Unit #": 1212,
    "Floor": 12,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor12"
  },
  {
    "id": 65,
    "Unit #": 1213,
    "Floor": 12,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor12"
  },
  {
    "id": 66,
    "Unit #": 1301,
    "Floor": 13,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 67,
    "Unit #": 1302,
    "Floor": 13,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 68,
    "Unit #": 1303,
    "Floor": 13,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 69,
    "Unit #": 1304,
    "Floor": 13,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 70,
    "Unit #": 1305,
    "Floor": 13,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 71,
    "Unit #": 1306,
    "Floor": 13,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins; Glass guardrail on Northeast",
    "View Img": "floor15"
  },
  {
    "id": 72,
    "Unit #": 1307,
    "Floor": 13,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 73,
    "Unit #": 1308,
    "Floor": 13,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 74,
    "Unit #": 1309,
    "Floor": 13,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 75,
    "Unit #": 1310,
    "Floor": 13,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 76,
    "Unit #": 1311,
    "Floor": 13,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 77,
    "Unit #": 1312,
    "Floor": 13,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor15"
  },
  {
    "id": 78,
    "Unit #": 1313,
    "Floor": 13,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 79,
    "Unit #": 1401,
    "Floor": 14,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 80,
    "Unit #": 1402,
    "Floor": 14,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 81,
    "Unit #": 1403,
    "Floor": 14,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 82,
    "Unit #": 1404,
    "Floor": 14,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 83,
    "Unit #": 1405,
    "Floor": 14,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 84,
    "Unit #": 1406,
    "Floor": 14,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor15"
  },
  {
    "id": 85,
    "Unit #": 1407,
    "Floor": 14,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 86,
    "Unit #": 1408,
    "Floor": 14,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 87,
    "Unit #": 1409,
    "Floor": 14,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 88,
    "Unit #": 1410,
    "Floor": 14,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 89,
    "Unit #": 1411,
    "Floor": 14,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 90,
    "Unit #": 1412,
    "Floor": 14,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor15"
  },
  {
    "id": 91,
    "Unit #": 1413,
    "Floor": 14,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 92,
    "Unit #": 1501,
    "Floor": 15,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 93,
    "Unit #": 1502,
    "Floor": 15,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 94,
    "Unit #": 1503,
    "Floor": 15,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 95,
    "Unit #": 1504,
    "Floor": 15,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 96,
    "Unit #": 1505,
    "Floor": 15,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 97,
    "Unit #": 1506,
    "Floor": 15,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor15"
  },
  {
    "id": 98,
    "Unit #": 1507,
    "Floor": 15,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 99,
    "Unit #": 1508,
    "Floor": 15,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 100,
    "Unit #": 1509,
    "Floor": 15,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 101,
    "Unit #": 1510,
    "Floor": 15,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor15"
  },
  {
    "id": 102,
    "Unit #": 1511,
    "Floor": 15,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor15"
  },
  {
    "id": 103,
    "Unit #": 1512,
    "Floor": 15,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor15"
  },
  {
    "id": 104,
    "Unit #": 1513,
    "Floor": 15,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor15"
  },
  {
    "id": 105,
    "Unit #": 1601,
    "Floor": 16,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 106,
    "Unit #": 1602,
    "Floor": 16,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 107,
    "Unit #": 1603,
    "Floor": 16,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 108,
    "Unit #": 1604,
    "Floor": 16,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 109,
    "Unit #": 1605,
    "Floor": 16,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 110,
    "Unit #": 1606,
    "Floor": 16,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor18"
  },
  {
    "id": 111,
    "Unit #": 1607,
    "Floor": 16,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 112,
    "Unit #": 1608,
    "Floor": 16,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 113,
    "Unit #": 1609,
    "Floor": 16,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 114,
    "Unit #": 1610,
    "Floor": 16,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 115,
    "Unit #": 1611,
    "Floor": 16,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 116,
    "Unit #": 1612,
    "Floor": 16,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor18"
  },
  {
    "id": 117,
    "Unit #": 1613,
    "Floor": 16,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 118,
    "Unit #": 1701,
    "Floor": 17,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 119,
    "Unit #": 1702,
    "Floor": 17,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 120,
    "Unit #": 1703,
    "Floor": 17,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 121,
    "Unit #": 1704,
    "Floor": 17,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 122,
    "Unit #": 1705,
    "Floor": 17,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 123,
    "Unit #": 1706,
    "Floor": 17,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor18"
  },
  {
    "id": 124,
    "Unit #": 1707,
    "Floor": 17,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 125,
    "Unit #": 1708,
    "Floor": 17,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 126,
    "Unit #": 1709,
    "Floor": 17,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 127,
    "Unit #": 1710,
    "Floor": 17,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 128,
    "Unit #": 1711,
    "Floor": 17,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 129,
    "Unit #": 1712,
    "Floor": 17,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor18"
  },
  {
    "id": 130,
    "Unit #": 1713,
    "Floor": 17,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 131,
    "Unit #": 1801,
    "Floor": 18,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 132,
    "Unit #": 1802,
    "Floor": 18,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 133,
    "Unit #": 1803,
    "Floor": 18,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 134,
    "Unit #": 1804,
    "Floor": 18,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 135,
    "Unit #": 1805,
    "Floor": 18,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 136,
    "Unit #": 1806,
    "Floor": 18,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor18"
  },
  {
    "id": 137,
    "Unit #": 1807,
    "Floor": 18,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 138,
    "Unit #": 1808,
    "Floor": 18,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 139,
    "Unit #": 1809,
    "Floor": 18,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 140,
    "Unit #": 1810,
    "Floor": 18,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor18"
  },
  {
    "id": 141,
    "Unit #": 1811,
    "Floor": 18,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor18"
  },
  {
    "id": 142,
    "Unit #": 1812,
    "Floor": 18,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor18"
  },
  {
    "id": 143,
    "Unit #": 1813,
    "Floor": 18,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor18"
  },
  {
    "id": 144,
    "Unit #": 1901,
    "Floor": 19,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 145,
    "Unit #": 1902,
    "Floor": 19,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 146,
    "Unit #": 1903,
    "Floor": 19,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 147,
    "Unit #": 1904,
    "Floor": 19,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 148,
    "Unit #": 1905,
    "Floor": 19,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 149,
    "Unit #": 1906,
    "Floor": 19,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor21"
  },
  {
    "id": 150,
    "Unit #": 1907,
    "Floor": 19,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 151,
    "Unit #": 1908,
    "Floor": 19,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 152,
    "Unit #": 1909,
    "Floor": 19,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 153,
    "Unit #": 1910,
    "Floor": 19,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 154,
    "Unit #": 1911,
    "Floor": 19,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 155,
    "Unit #": 1912,
    "Floor": 19,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor21"
  },
  {
    "id": 156,
    "Unit #": 1913,
    "Floor": 19,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 157,
    "Unit #": 2001,
    "Floor": 20,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 158,
    "Unit #": 2002,
    "Floor": 20,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 159,
    "Unit #": 2003,
    "Floor": 20,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 160,
    "Unit #": 2004,
    "Floor": 20,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 161,
    "Unit #": 2005,
    "Floor": 20,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 162,
    "Unit #": 2006,
    "Floor": 20,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor21"
  },
  {
    "id": 163,
    "Unit #": 2007,
    "Floor": 20,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 164,
    "Unit #": 2008,
    "Floor": 20,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 165,
    "Unit #": 2009,
    "Floor": 20,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 166,
    "Unit #": 2010,
    "Floor": 20,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 167,
    "Unit #": 2011,
    "Floor": 20,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 168,
    "Unit #": 2012,
    "Floor": 20,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor21"
  },
  {
    "id": 169,
    "Unit #": 2013,
    "Floor": 20,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 170,
    "Unit #": 2101,
    "Floor": 21,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 171,
    "Unit #": 2102,
    "Floor": 21,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 172,
    "Unit #": 2103,
    "Floor": 21,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 173,
    "Unit #": 2104,
    "Floor": 21,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 174,
    "Unit #": 2105,
    "Floor": 21,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 175,
    "Unit #": 2106,
    "Floor": 21,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor21"
  },
  {
    "id": 176,
    "Unit #": 2107,
    "Floor": 21,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 177,
    "Unit #": 2108,
    "Floor": 21,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 178,
    "Unit #": 2109,
    "Floor": 21,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 179,
    "Unit #": 2110,
    "Floor": 21,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor21"
  },
  {
    "id": 180,
    "Unit #": 2111,
    "Floor": 21,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor21"
  },
  {
    "id": 181,
    "Unit #": 2112,
    "Floor": 21,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor21"
  },
  {
    "id": 182,
    "Unit #": 2113,
    "Floor": 21,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor21"
  },
  {
    "id": 183,
    "Unit #": 2201,
    "Floor": 22,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 184,
    "Unit #": 2202,
    "Floor": 22,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 185,
    "Unit #": 2203,
    "Floor": 22,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 186,
    "Unit #": 2204,
    "Floor": 22,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 187,
    "Unit #": 2205,
    "Floor": 22,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 188,
    "Unit #": 2206,
    "Floor": 22,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor24"
  },
  {
    "id": 189,
    "Unit #": 2207,
    "Floor": 22,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 190,
    "Unit #": 2208,
    "Floor": 22,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 191,
    "Unit #": 2209,
    "Floor": 22,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 192,
    "Unit #": 2210,
    "Floor": 22,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 193,
    "Unit #": 2211,
    "Floor": 22,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 194,
    "Unit #": 2212,
    "Floor": 22,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor24"
  },
  {
    "id": 195,
    "Unit #": 2213,
    "Floor": 22,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 196,
    "Unit #": 2301,
    "Floor": 23,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 197,
    "Unit #": 2302,
    "Floor": 23,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 198,
    "Unit #": 2303,
    "Floor": 23,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 199,
    "Unit #": 2304,
    "Floor": 23,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 200,
    "Unit #": 2305,
    "Floor": 23,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 201,
    "Unit #": 2306,
    "Floor": 23,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor24"
  },
  {
    "id": 202,
    "Unit #": 2307,
    "Floor": 23,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 203,
    "Unit #": 2308,
    "Floor": 23,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 204,
    "Unit #": 2309,
    "Floor": 23,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 205,
    "Unit #": 2310,
    "Floor": 23,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 206,
    "Unit #": 2311,
    "Floor": 23,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 207,
    "Unit #": 2312,
    "Floor": 23,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor24"
  },
  {
    "id": 208,
    "Unit #": 2313,
    "Floor": 23,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 209,
    "Unit #": 2401,
    "Floor": 24,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 210,
    "Unit #": 2402,
    "Floor": 24,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 211,
    "Unit #": 2403,
    "Floor": 24,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 212,
    "Unit #": 2404,
    "Floor": 24,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 213,
    "Unit #": 2405,
    "Floor": 24,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 214,
    "Unit #": 2406,
    "Floor": 24,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor24"
  },
  {
    "id": 215,
    "Unit #": 2407,
    "Floor": 24,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 216,
    "Unit #": 2408,
    "Floor": 24,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor24"
  },
  {
    "id": 217,
    "Unit #": 2409,
    "Floor": 24,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 218,
    "Unit #": 2410,
    "Floor": 24,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 219,
    "Unit #": 2411,
    "Floor": 24,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor24"
  },
  {
    "id": 220,
    "Unit #": 2412,
    "Floor": 24,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor24"
  },
  {
    "id": 221,
    "Unit #": 2413,
    "Floor": 24,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor24"
  },
  {
    "id": 222,
    "Unit #": 2501,
    "Floor": 25,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 223,
    "Unit #": 2502,
    "Floor": 25,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 224,
    "Unit #": 2503,
    "Floor": 25,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 225,
    "Unit #": 2504,
    "Floor": 25,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 226,
    "Unit #": 2505,
    "Floor": 25,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 227,
    "Unit #": 2506,
    "Floor": 25,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor27"
  },
  {
    "id": 228,
    "Unit #": 2507,
    "Floor": 25,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 229,
    "Unit #": 2508,
    "Floor": 25,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 230,
    "Unit #": 2509,
    "Floor": 25,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 231,
    "Unit #": 2510,
    "Floor": 25,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 232,
    "Unit #": 2511,
    "Floor": 25,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 233,
    "Unit #": 2512,
    "Floor": 25,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor27"
  },
  {
    "id": 234,
    "Unit #": 2513,
    "Floor": 25,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 235,
    "Unit #": 2601,
    "Floor": 26,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 236,
    "Unit #": 2602,
    "Floor": 26,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 237,
    "Unit #": 2603,
    "Floor": 26,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 238,
    "Unit #": 2604,
    "Floor": 26,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 239,
    "Unit #": 2605,
    "Floor": 26,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 240,
    "Unit #": 2606,
    "Floor": 26,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor27"
  },
  {
    "id": 241,
    "Unit #": 2607,
    "Floor": 26,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 242,
    "Unit #": 2608,
    "Floor": 26,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 243,
    "Unit #": 2609,
    "Floor": 26,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 244,
    "Unit #": 2610,
    "Floor": 26,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 245,
    "Unit #": 2611,
    "Floor": 26,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 246,
    "Unit #": 2612,
    "Floor": 26,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor27"
  },
  {
    "id": 247,
    "Unit #": 2613,
    "Floor": 26,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 248,
    "Unit #": 2701,
    "Floor": 27,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 249,
    "Unit #": 2702,
    "Floor": 27,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 250,
    "Unit #": 2703,
    "Floor": 27,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 251,
    "Unit #": 2704,
    "Floor": 27,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 252,
    "Unit #": 2705,
    "Floor": 27,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 253,
    "Unit #": 2706,
    "Floor": 27,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor27"
  },
  {
    "id": 254,
    "Unit #": 2707,
    "Floor": 27,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 255,
    "Unit #": 2708,
    "Floor": 27,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor27"
  },
  {
    "id": 256,
    "Unit #": 2709,
    "Floor": 27,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 257,
    "Unit #": 2710,
    "Floor": 27,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 258,
    "Unit #": 2711,
    "Floor": 27,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor27"
  },
  {
    "id": 259,
    "Unit #": 2712,
    "Floor": 27,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor27"
  },
  {
    "id": 260,
    "Unit #": 2713,
    "Floor": 27,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor27"
  },
  {
    "id": 261,
    "Unit #": 2801,
    "Floor": 28,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": 262,
    "Unit #": 2802,
    "Floor": 28,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": 263,
    "Unit #": 2803,
    "Floor": 28,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": 264,
    "Unit #": 2804,
    "Floor": 28,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": 265,
    "Unit #": 2805,
    "Floor": 28,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": 266,
    "Unit #": 2806,
    "Floor": 28,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Concrete Upstand with Glass (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor30"
  },
  {
    "id": 267,
    "Unit #": 2807,
    "Floor": 28,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor30"
  },
  {
    "id": 268,
    "Unit #": 2808,
    "Floor": 28,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2809,
    "Floor": 28,
    "Unit": "X09",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2810,
    "Floor": 28,
    "Unit": "X10",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2811,
    "Floor": 28,
    "Unit": "X11",
    "Plan Type": "C",
    "Size (Interior)": 586,
    "Size (Exterior)": 125,
    "Size (Total)": 711,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2812,
    "Floor": 28,
    "Unit": "X12",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2813,
    "Floor": 28,
    "Unit": "X13",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2901,
    "Floor": 29,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2902,
    "Floor": 29,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2903,
    "Floor": 29,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2904,
    "Floor": 29,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NNE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2905,
    "Floor": 29,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2906,
    "Floor": 29,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2907,
    "Floor": 29,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2908,
    "Floor": 29,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2909,
    "Floor": 29,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2910,
    "Floor": 29,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2911,
    "Floor": 29,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 2912,
    "Floor": 29,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3001,
    "Floor": 30,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3002,
    "Floor": 30,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3003,
    "Floor": 30,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3004,
    "Floor": 30,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3005,
    "Floor": 30,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3006,
    "Floor": 30,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3007,
    "Floor": 30,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3008,
    "Floor": 30,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3009,
    "Floor": 30,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3010,
    "Floor": 30,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3011,
    "Floor": 30,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3012,
    "Floor": 30,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3101,
    "Floor": 31,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor30"
  },
  {
    "id": "",
    "Unit #": 3102,
    "Floor": 31,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3103,
    "Floor": 31,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3104,
    "Floor": 31,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3105,
    "Floor": 31,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3106,
    "Floor": 31,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3107,
    "Floor": 31,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3108,
    "Floor": 31,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3109,
    "Floor": 31,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3110,
    "Floor": 31,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3111,
    "Floor": 31,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3112,
    "Floor": 31,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3201,
    "Floor": 32,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3202,
    "Floor": 32,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3203,
    "Floor": 32,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3204,
    "Floor": 32,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3205,
    "Floor": 32,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3206,
    "Floor": 32,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3207,
    "Floor": 32,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3208,
    "Floor": 32,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3209,
    "Floor": 32,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3210,
    "Floor": 32,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3211,
    "Floor": 32,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3212,
    "Floor": 32,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3301,
    "Floor": 33,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3302,
    "Floor": 33,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3303,
    "Floor": 33,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3304,
    "Floor": 33,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3305,
    "Floor": 33,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3306,
    "Floor": 33,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3307,
    "Floor": 33,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3308,
    "Floor": 33,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3309,
    "Floor": 33,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3310,
    "Floor": 33,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3311,
    "Floor": 33,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3312,
    "Floor": 33,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3401,
    "Floor": 34,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor33"
  },
  {
    "id": "",
    "Unit #": 3402,
    "Floor": 34,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3403,
    "Floor": 34,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3404,
    "Floor": 34,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3405,
    "Floor": 34,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3406,
    "Floor": 34,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3407,
    "Floor": 34,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3408,
    "Floor": 34,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3409,
    "Floor": 34,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3410,
    "Floor": 34,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3411,
    "Floor": 34,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3412,
    "Floor": 34,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3501,
    "Floor": 35,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3502,
    "Floor": 35,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3503,
    "Floor": 35,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3504,
    "Floor": 35,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3505,
    "Floor": 35,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3506,
    "Floor": 35,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3507,
    "Floor": 35,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3508,
    "Floor": 35,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3509,
    "Floor": 35,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3510,
    "Floor": 35,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3511,
    "Floor": 35,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3512,
    "Floor": 35,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3601,
    "Floor": 36,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3602,
    "Floor": 36,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3603,
    "Floor": 36,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3604,
    "Floor": 36,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3605,
    "Floor": 36,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3606,
    "Floor": 36,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3607,
    "Floor": 36,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3608,
    "Floor": 36,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3609,
    "Floor": 36,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3610,
    "Floor": 36,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3611,
    "Floor": 36,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3612,
    "Floor": 36,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor36"
  },
  {
    "id": "",
    "Unit #": 3701,
    "Floor": 37,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3702,
    "Floor": 37,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3703,
    "Floor": 37,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3704,
    "Floor": 37,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3705,
    "Floor": 37,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3706,
    "Floor": 37,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3707,
    "Floor": 37,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3708,
    "Floor": 37,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3709,
    "Floor": 37,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3710,
    "Floor": 37,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3711,
    "Floor": 37,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3712,
    "Floor": 37,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3801,
    "Floor": 38,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3802,
    "Floor": 38,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3803,
    "Floor": 38,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3804,
    "Floor": 38,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3805,
    "Floor": 38,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3806,
    "Floor": 38,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3807,
    "Floor": 38,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3808,
    "Floor": 38,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3809,
    "Floor": 38,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with 1/2 Height Glass Fins",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3810,
    "Floor": 38,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Height Glass fin",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3811,
    "Floor": 38,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3812,
    "Floor": 38,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3901,
    "Floor": 39,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3902,
    "Floor": 39,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3903,
    "Floor": 39,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3904,
    "Floor": 39,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3905,
    "Floor": 39,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3906,
    "Floor": 39,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3907,
    "Floor": 39,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3908,
    "Floor": 39,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3909,
    "Floor": 39,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3910,
    "Floor": 39,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3911,
    "Floor": 39,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 3912,
    "Floor": 39,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor39"
  },
  {
    "id": "",
    "Unit #": 4001,
    "Floor": 40,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4002,
    "Floor": 40,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4003,
    "Floor": 40,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4004,
    "Floor": 40,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4005,
    "Floor": 40,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4006,
    "Floor": 40,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4007,
    "Floor": 40,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4008,
    "Floor": 40,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4009,
    "Floor": 40,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4010,
    "Floor": 40,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4011,
    "Floor": 40,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4012,
    "Floor": 40,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4101,
    "Floor": 41,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4102,
    "Floor": 41,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4103,
    "Floor": 41,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4104,
    "Floor": 41,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4105,
    "Floor": 41,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4106,
    "Floor": 41,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4107,
    "Floor": 41,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4108,
    "Floor": 41,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4109,
    "Floor": 41,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4110,
    "Floor": 41,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4111,
    "Floor": 41,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4112,
    "Floor": 41,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4201,
    "Floor": 42,
    "Unit": "X01",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4202,
    "Floor": 42,
    "Unit": "X02",
    "Plan Type": "D",
    "Size (Interior)": 669,
    "Size (Exterior)": 295,
    "Size (Total)": 964,
    "Bedroom Type": "1 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4203,
    "Floor": 42,
    "Unit": "X03",
    "Plan Type": "A",
    "Size (Interior)": 393,
    "Size (Exterior)": 64,
    "Size (Total)": 457,
    "Bedroom Type": "Studio",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4204,
    "Floor": 42,
    "Unit": "X04",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4205,
    "Floor": 42,
    "Unit": "X05",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4206,
    "Floor": 42,
    "Unit": "X06",
    "Plan Type": "H",
    "Size (Interior)": 1077,
    "Size (Exterior)": 359,
    "Size (Total)": 1436,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4207,
    "Floor": 42,
    "Unit": "X07",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4208,
    "Floor": 42,
    "Unit": "X08",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4209,
    "Floor": 42,
    "Unit": "X09",
    "Plan Type": "K",
    "Size (Interior)": 1232,
    "Size (Exterior)": 402,
    "Size (Total)": 1634,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4210,
    "Floor": 42,
    "Unit": "X10",
    "Plan Type": "F",
    "Size (Interior)": 880,
    "Size (Exterior)": 188,
    "Size (Total)": 1068,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4211,
    "Floor": 42,
    "Unit": "X11",
    "Plan Type": "G",
    "Size (Interior)": 940,
    "Size (Exterior)": 339,
    "Size (Total)": 1279,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4212,
    "Floor": 42,
    "Unit": "X12",
    "Plan Type": "B",
    "Size (Interior)": 548,
    "Size (Exterior)": 74,
    "Size (Total)": 622,
    "Bedroom Type": "Urban 1 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4301,
    "Floor": 43,
    "Unit": "X01",
    "Plan Type": "M",
    "Size (Interior)": 1662,
    "Size (Exterior)": 433,
    "Size (Total)": 2095,
    "Bedroom Type": "3 Bedroom+Family",
    "Status": "Available",
    "View": "NWN",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4302,
    "Floor": 43,
    "Unit": "X02",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor42"
  },
  {
    "id": "",
    "Unit #": 4303,
    "Floor": 43,
    "Unit": "X03",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4304,
    "Floor": 43,
    "Unit": "X04",
    "Plan Type": "M",
    "Size (Interior)": 1662,
    "Size (Exterior)": 433,
    "Size (Total)": 2095,
    "Bedroom Type": "3 Bedroom+Family",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street) AND Glass Guardrail on Northeast exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4305,
    "Floor": 43,
    "Unit": "X05",
    "Plan Type": "L",
    "Size (Interior)": 1522,
    "Size (Exterior)": 492,
    "Size (Total)": 2014,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "S",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4306,
    "Floor": 43,
    "Unit": "X06",
    "Plan Type": "J",
    "Size (Interior)": 1173,
    "Size (Exterior)": 250,
    "Size (Total)": 1423,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4307,
    "Floor": 43,
    "Unit": "X07",
    "Plan Type": "L",
    "Size (Interior)": 1522,
    "Size (Exterior)": 492,
    "Size (Total)": 2014,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4401,
    "Floor": 44,
    "Unit": "X01",
    "Plan Type": "M",
    "Size (Interior)": 1662,
    "Size (Exterior)": 433,
    "Size (Total)": 2095,
    "Bedroom Type": "3 Bedroom+Family",
    "Status": "Available",
    "View": "NE",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4402,
    "Floor": 44,
    "Unit": "X02",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4403,
    "Floor": 44,
    "Unit": "X03",
    "Plan Type": "E",
    "Size (Interior)": 686,
    "Size (Exterior)": 125,
    "Size (Total)": 811,
    "Bedroom Type": "Urban 2 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4404,
    "Floor": 44,
    "Unit": "X04",
    "Plan Type": "M",
    "Size (Interior)": 1662,
    "Size (Exterior)": 433,
    "Size (Total)": 2095,
    "Bedroom Type": "3 Bedroom+Family",
    "Status": "Available",
    "View": "SE",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street) AND Glass Guardrail on Northeast exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4405,
    "Floor": 44,
    "Unit": "X05",
    "Plan Type": "L",
    "Size (Interior)": 1522,
    "Size (Exterior)": 492,
    "Size (Total)": 2014,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "SW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4406,
    "Floor": 44,
    "Unit": "X06",
    "Plan Type": "J",
    "Size (Interior)": 1173,
    "Size (Exterior)": 250,
    "Size (Total)": 1423,
    "Bedroom Type": "2 Bedroom",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins AND 1/2 Concrete Upstand with Glass",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4407,
    "Floor": 44,
    "Unit": "X07",
    "Plan Type": "L",
    "Size (Interior)": 1522,
    "Size (Exterior)": 492,
    "Size (Total)": 2014,
    "Bedroom Type": "3 Bedroom",
    "Status": "Available",
    "View": "NW",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4501,
    "Floor": 45,
    "Unit": "X01",
    "Plan Type": "N",
    "Size (Interior)": 1841,
    "Size (Exterior)": 492,
    "Size (Total)": 2333,
    "Bedroom Type": "Penthouse/ 3 Bedroom",
    "Status": "Available",
    "View": "N",
    "Balcony: Full Glass,": "Glass Guardrail",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4502,
    "Floor": 45,
    "Unit": "X02",
    "Plan Type": "N",
    "Size (Interior)": 1841,
    "Size (Exterior)": 492,
    "Size (Total)": 2333,
    "Bedroom Type": "Penthouse/ 3 Bedroom",
    "Status": "Available",
    "View": "E",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (Virginia Street); Glass Guardrail on Northeast exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4503,
    "Floor": 45,
    "Unit": "X03",
    "Plan Type": "Q",
    "Size (Interior)": 2755,
    "Size (Exterior)": 1310,
    "Size (Total)": 4065,
    "Bedroom Type": "Penthouse/ 3 Bedroom+Family",
    "Status": "Available",
    "View": "SSW",
    "Balcony: Full Glass,": "Concrete Upstand with Glass",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 4504,
    "Floor": 45,
    "Unit": "X04",
    "Plan Type": "P",
    "Size (Interior)": 2021,
    "Size (Exterior)": 479,
    "Size (Total)": 2500,
    "Bedroom Type": "Penthouse/ 3 Bedroom+Family",
    "Status": "Available",
    "View": "W",
    "Balcony: Full Glass,": "Glass Guardrail with Full Height Glass Fins (3rd Ave); Glass Guardrail on West exposure",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 7,
    "Floor": 7,
    "Unit": "Podium 7",
    "Plan Type": "P",
    "Size (Interior)": "",
    "Size (Exterior)": "",
    "Size (Total)": "",
    "Office": 9625,
    "Orchard + Terrace": 9344,
    "Status": "Available",
    "View": "",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 6,
    "Floor": 6,
    "Unit": "Podium 6",
    "Plan Type": "P",
    "Size (Interior)": "",
    "Size (Exterior)": "",
    "Size (Total)": "",
    "Office": 19228,
    "Status": "Available",
    "View": "",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 3,
    "Floor": 3,
    "Unit": "Podium 3-5",
    "Plan Type": "P",
    "Size (Interior)": "",
    "Size (Exterior)": "",
    "Size (Total)": "",
    "Office": 19781,
    "Status": "Available",
    "View": "",
    "View Img": "floor45"
  },
  {
    "id": "",
    "Unit #": 2,
    "Floor": 2,
    "Unit": "X01",
    "Plan Type": "P",
    "Size (Interior)": "",
    "Size (Exterior)": "",
    "Size (Total)": "",
    "Office": 19781,
    "Status": "Available",
    "View": "",
    "View Img": "floor45"
  }
]
