'use strict'

const self = {
  container : document.querySelector('.nav--dropdown.anchor'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.linkContainer = self.container.querySelector('.nav--dropdown-list')
    self.anchorTitle = self.container.querySelector('.anchor-title')
  },

  registerEvents : () => {
    self.linkContainer.addEventListener('click', self.goToAnchor)
  },

  goToAnchor : e => {
    if ( e.target.nodeName !== 'A' ) return false

    const link = e.target
    const label = link.innerText

    self.anchorTitle.innerText = label
  }

}

export default self
