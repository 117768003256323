'use strict'

import Player from '@vimeo/player'

const self = {
  containers : [...document.querySelectorAll('figure.inline-video')],

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {

  },

  registerEvents : () => {
    self.containers.forEach( container => container.addEventListener('click', self.toggleVideo) )
  },

  toggleVideo : e => {
    const figure = e.currentTarget;
    const iframe = figure.querySelector('iframe')
    const player = new Player(iframe)

    figure.classList.toggle('is-playing')
    player.play()
  }

}

export default self
