'use strict'

import AutoPan from './autoPan'

const self = {
  container : document.querySelector('.page-architecture'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new AutoPan({
      el       : document.querySelectorAll('.architecture-1'),
    })
    new AutoPan({
      el       : document.querySelectorAll('.architecture-2'),
    })
    new AutoPan({
      el       : document.querySelectorAll('.architecture-3'),
    })
  },

}

export default self
