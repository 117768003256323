
export default class AutoPan {
  constructor (args) {

    // Set all variables
    this.el = args.el
    this.panFlg = false

    // Watch scroll
    window.addEventListener('scroll', this.onScroll.bind(this))

  }

  onScroll(e) {

    if (this.el.length > 1) {
      if (!this.panFlg && (this.el[0].getBoundingClientRect().top < window.innerHeight - 200)) {

        this.el.forEach( el => {
          el.classList.add('show')
        })
        this.panFlg = true

      } else if (this.panFlg && (this.el[0].getBoundingClientRect().top >= window.innerHeight + 100)) {

        this.el.forEach( el => {
          el.classList.remove('show')
        })
        this.panFlg = false
      }
    } else {
      if (!this.panFlg && (this.el.getBoundingClientRect().top < window.innerHeight - 200)) {
        this.el.classList.add('show')
        this.panFlg = true

      } else if (this.panFlg && (this.el.getBoundingClientRect().top >= window.innerHeight + 100)) {
        this.el.classList.remove('show')
        this.panFlg = false
      }
    }

  }

}
