'use strict'

const self = {
  container : document.querySelector('.popup--msg'),

  init : () => {
    self.setupElements()
    self.registerEvents()
    if ( document.body.classList.contains('home') ) setTimeout(self.toggleVisibility, 1000)
  },

  setupElements : () => {
    self.btnClose = self.container.querySelector('.btn-close')
  },

  registerEvents : () => {
    self.btnClose.addEventListener('click', self.toggleVisibility)
  },

  toggleVisibility : e => self.container.classList.toggle('offcanvas')

}

export default self
