
import data from '../data/fl_data_v1.json'
import PanoViewer from '../classes/PanoViewer'

const self = {

	// Plan Data
	results: [],
	data: data,
	assetsDir         : '/app/themes/firstlightseattle/assets/img/flp',
	// assetsDir: '/wp-content/themes/firstlightseattle/assets/img/flp',
	currentViewGroup: null,
	currentViewFloor: null,
	duskViewIsActive: false,

	floorGroups: [
		// { name: 'Floor 2', units: [] },
		// { name: 'Floors 3 - 5', units: [] },
		// { name: 'Floor 6', units: [] },
		// { name: 'Floor 7', units: [] },
		{ name: 'Floors 9 - 28', units: [] },
		{ name: 'Floors 29 - 42', units: [] },
		{ name: 'Floors 43 - 44', units: [] },
		{ name: 'Floor 45', units: [] }
	],

	viewGroups: [
		{
			name: 'Retail',
			floors: [1],
		},
		{
			name: 'Floor 2',
			floors: [2],
		},
		{
			name: 'Floors 3 - 5',
			floors: [3, 4, 5]
		},
		{
			name: 'Floor 6',
			floors: [6],
		},
		{
			name: 'Floor 7',
			floors: [7],
		},
		{
			name: 'Floors 9 - 28',
			floors: [9, 12, 15, 18, 21, 24, 27],
			dusk: [21]
		},
		{
			name: 'Floors 29 - 42',
			floors: [30, 33, 36, 39, 42],
			dusk: [36]
		},
		{
			name: 'Floors 43 - 44',
			floors: [42, 42]
		},
		{
			name: 'Floor 45',
			floors: [45, 45]
		}
	],

	mx: {
		level: "Floor",            // Number
		number: "Unit #",           // Number
		unit: "Unit",
		type: "Bedroom Type",      // String
		filterLabel: "Filter Label",     // String
		planType: "Plan Type",        // String
		dir: "View",             // String
		sizeInterior: "Size (Interior)",
		sizeExterior: "Size (Exterior)",
		totalLiving: "Size (Total)",
		viewImg: "View Img",          // String
		sizeOffice: "Office",
		sizeOrchardTerrace: "Orchard + Terrace"
	},

	elms: {
		container: document.querySelector('.page__floorplans'),
		floorGroupBtns: document.querySelectorAll('.building-selector .floorgroups button'),
		podiumGroupBtns: document.querySelectorAll('.building-selector .floorgroups button.podiumgroup'),
		floorPlateContainer: document.querySelector('.building-selector .floorplate-image'),
		plateHitboxes: document.querySelectorAll('.unit-hitbox'),
		unitPagerBtns: null,
		groupHeaders: null,
		floorGroups: document.querySelector('.plan-selector .floor-groups'),
		panoViewImage: document.querySelector('.overlay__plans--views .view-img'),
		overlay: document.querySelector('.overlay__plans'),
		overlayCloseBtn: document.querySelector('.overlay__plans .btn--close'),
		viewSelector: document.querySelector('.overlay__plans .view-selector'),
		viewSelectorBtns: document.querySelectorAll('.overlay__plans .view-selector button'),
		viewSelectorLabel: document.querySelector('.overlay__plans .view-selector .label'),
		viewToggleAlt: document.querySelector('.overlay__plans .view-selector .toggle-alt'),
		viewToggleAltBtns: document.querySelectorAll('.overlay__plans .view-selector .toggle-alt span')
	},

	init: () => {

		self.setFloorGroupsUnits()
		self.createHtmlFromFloorGroups()

		// Buttons are created in createHtmlFromFloorGroups
		self.elms.unitPagerBtns = document.querySelectorAll('.group__btn--prev,.group__btn--next')
		self.elms.groupHeaders = document.querySelectorAll('.floor-groups .group nav')
		self.elms.unitArticles = document.querySelectorAll('.floor-groups article')

		self.registerEvents()
		self.elms.groupHeaders[0].click()
	},


	registerEvents: () => {
		self.elms.floorGroupBtns.forEach(button => button.addEventListener('click', self.changeFloorplate))
		self.elms.podiumGroupBtns.forEach(button => button.addEventListener('click', self.launchOverlay))
		self.elms.plateHitboxes.forEach(button => button.addEventListener('click', self.launchOverlay))
		self.elms.unitPagerBtns.forEach(button => button.addEventListener('click', self.changeFocusedUnit))
		self.elms.groupHeaders.forEach(button => button.addEventListener('click', self.toggleGroupActive))
		self.elms.unitArticles.forEach(button => button.addEventListener('click', self.launchOverlay))
		self.elms.viewSelectorBtns.forEach(button => button.addEventListener('click', self.switchView))
		self.elms.viewToggleAltBtns.forEach(button => button.addEventListener('click', self.toggleViewAlt))
		self.elms.overlayCloseBtn.addEventListener('click', self.closeOverlay)

		window.addEventListener('resize', self.onResize)

		const panoViewer = new PanoViewer({ el: self.elms.panoViewImage })
	},




	// Building Selector
	// -------------------------------

	changeFloorplate: e => {
		const button = e.currentTarget
		const activeButton = button.parentNode.querySelector('button.is-active')
		const { floor } = button.dataset
		const activePlate = self.elms.floorPlateContainer.querySelector(`.is-active`)
		const targetPlate = self.elms.floorPlateContainer.querySelector(`[data-floor="${floor}"]`)

		if (activeButton) activeButton.classList.remove('is-active')
		button.classList.add('is-active')

		activePlate.classList.remove('is-active')
		targetPlate.classList.add('is-active')
	},

	launchOverlay: e => {
		const button = e.currentTarget
		let floors = button.parentNode.dataset.floors
		let unit = self.getUnitWith(button.dataset.unit)

		if (['1', '2', '3', '6', '7'].includes(button.dataset.floor)) {
			floors = button.dataset.floors
			unit = self.getUnitWith(button.dataset.unit)
		}

		// console.log('floors', floors)
		// console.log('unit', unit)

		self.openOverlay(floors, unit)
	},







	// Unit Selector
	// -------------------------------

	createHtmlFromFloorGroups: () => {
		self.floorGroups.forEach((group, i) => {
			const { name, units } = group

			const groupContainer = document.createElement('div')
			groupContainer.className = 'group'
			groupContainer.dataset.name = name

			const groupNav = self.getGroupNav(name)
			groupContainer.append(groupNav)

			const groupUnits = self.getGroupUnits(units, name)
			groupContainer.append(groupUnits)

			self.elms.floorGroups.append(groupContainer)
		})
	},

	getGroupNav: name => {
		const nav = document.createElement('nav')

		nav.innerHTML = `
      <div class="inner-wrap">
        <span class="group__title">${name}</span>
        <aside>
          <button class="group__btn--prev" disabled> <i class="material-icons">keyboard_arrow_left</i> </button>
          <button class="group__btn--next" disabled> <i class="material-icons">keyboard_arrow_right</i> </button>
        </aside>
      </div>
    `

		return nav
	},

	getGroupUnits: (units, name) => {
		let i = 0

		const accImgDir = '/app/themes/firstlightseattle/assets/img/flp/pdfs/acc'
		// const accImgDir = '/wp-content/themes/firstlightseattle/assets/img/flp/pdfs/acc'

		const groupUnitsContainer = document.createElement('div')
		groupUnitsContainer.className = 'group__units'

		const transformWrap = document.createElement('div')
		transformWrap.className = 'transform-wrap'
		transformWrap.style.transform = `translate3d(${window.innerWidth}px,0,0)`
		transformWrap.setAttribute('data-floors', name)

		units.forEach(unit => {
			let accLink = ''
			let filename = self.getFileNameFrom(unit)

			let unitNumber = `${unit[self.mx.unit]}`.replace("X", "")
			unitNumber = parseInt(unitNumber)

			if (unitNumber < 10) unitNumber = `0${unitNumber}`

			// -------------
			if (name === 'Floors 9 - 28') {
				if (unitNumber === '04') accLink = `<a href="${accImgDir}/3V Type E acc 1704-1804-1904-2004.pdf" target="_blank">Accessible Units: 1704, 1804, 1904, 2004</a>`
				if (unitNumber === '06') accLink = `<a href="${accImgDir}/3V Type H acc 2206-2306-2406.pdf" target="_blank">Accessible Units: 2206, 2306, 2406</a>`
				if (unitNumber === 10) accLink = `<a href="${accImgDir}/3V Type C acc 2120-2310-2410-2710.pdf" target="_blank">Accessible Units: 2110, 2310, 2410, 2710</a>`
			} else if (name === 'Floors 29 - 42') {
				if (unitNumber === '03') accLink = `<a href="${accImgDir}/3V Type A acc 2903-3003-3203.pdf" target="_blank">Accessible Units: 2903, 3003, 3203</a>`
				if (unitNumber === '06') accLink = `<a href="${accImgDir}/3V Type H acc 2906-3006.pdf" target="_blank">Accessible Units: 2906, 3006</a>`
				if (unitNumber === '09') accLink = `<a href="${accImgDir}/3V Type K acc 3009.pdf" target="_blank">Accessible Units: 3009</a>`
			}
			// -------------

			const unitHtml = `
        <article class="unit" data-index="${i}" data-unit="${unit[self.mx.number]}">
          <div class="content-wrap">
            <aside>
              <div class="unit_aside--top">
                <span class="unit__number">${unitNumber}</span>

                <span class="unit__type">${unit[self.mx.type]}</span>
                <span class="unit__total-area">Total Area: ${unit[self.mx.totalLiving]} sq ft</span>

                <span class="unit__interior-size">Interior Size: ${unit[self.mx.sizeInterior]} sq ft</span>
                <span class="unit__exterior-size">Exterior Size: ${unit[self.mx.sizeExterior]} sq ft</span>

                ${accLink}
              </div>

              <img class="unit__floorplate" src="${self.assetsDir}/plates/${filename}.svg" alt="">
            </aside>

            <figure>
              <img src="${self.assetsDir}/plans/${filename}.png" alt="">
            </figure>
          </div>
        </article>
      `

			transformWrap.innerHTML += unitHtml
			i += 1
		})

		transformWrap.append()
		groupUnitsContainer.append(transformWrap)

		return groupUnitsContainer
	},

	changeFocusedUnit: (e, group = null) => {
		const button = e ? e.currentTarget : null

		// Chrome does this automatically but just incase
		if (button && button.disabled) return false

		const direction = button ? button.className.replace("group__btn--", "") : 'next'
		const groupContainer = button ? self.getParentNodeWith({ cls: 'group', from: button }) : group
		const transformWrap = groupContainer.querySelector('.transform-wrap')
		const activeUnit = groupContainer.querySelector('.unit.is-active')
		const prevUnit = button ? activeUnit.previousElementSibling : null
		const nextUnit = button ? activeUnit.nextElementSibling : groupContainer.querySelector('.unit')

		const targetUnit = direction === 'next' ? nextUnit : prevUnit
		if (!targetUnit) return

		if (activeUnit) activeUnit.classList.remove('is-active')
		targetUnit.classList.add('is-active')

		const newTransformValue = self.getTransformPositionFrom({ i: targetUnit.dataset.index, el: targetUnit })
		transformWrap.style.transform = `translate3d(${newTransformValue}px,0,0)`

		self.manageButtonStates(groupContainer, targetUnit)
	},

	getTransformPositionFrom({ i, el }) {
		const index = -parseInt(i)
		const elStyle = window.getComputedStyle(el)
		const cw = el.clientWidth
		const ww = window.innerWidth / 2
		const m = parseInt(elStyle.getPropertyValue('margin-left').replace('px', ''))
		const eq = (cw * index) + ww - (cw / 2)

		return eq
	},

	manageButtonStates: (groupContainer, activeUnit) => {
		const btnPrev = groupContainer.querySelector('.group__btn--prev')
		const btnNext = groupContainer.querySelector('.group__btn--next')

		if (btnPrev === null || btnNext === null) return

		btnPrev.disabled = (!activeUnit.previousElementSibling) ? true : false
		btnNext.disabled = (!activeUnit.nextElementSibling) ? true : false
	},

	toggleGroupActive: e => {
		const nav = e.currentTarget
		const group = nav.parentNode
		const activeGroup = group.parentNode.querySelector('.group.is-active')
		const isGroupActive = group.classList.contains('is-active')

		// Already active, do nothing
		if (isGroupActive) return

		// Active group found, remove class
		if (activeGroup) {
			activeGroup.querySelector('.group__units').removeAttribute('style')
			activeGroup.classList.remove('is-active')
		}

		// Open target group
		const targetGroupContent = group.querySelector('.transform-wrap')
		const targetGroupHeight = targetGroupContent.clientHeight
		const groupUnits = targetGroupContent.parentNode

		groupUnits.style.height = `${targetGroupHeight}px`
		group.classList.add('is-active')

		if (!group.querySelector('.unit.is-active')) self.changeFocusedUnit(null, group)

	},









	// Overlay
	// -------------------------------

	openOverlay: (floors, unit) => {
		// console.log('floors', floors)
		// console.log('unit', unit)
		const unitNumber = `${unit[self.mx.number]}`.replace(unit[self.mx.level], '')
		const filename = self.getFileNameFrom(unit)
		const viewOffset = self.getViewOffset(unit[self.mx.dir])
		// console.log(unit[self.mx.dir], viewOffset);

		// Set data
		self.elms.overlay.querySelector('.unit__number').innerHTML = unitNumber
		self.elms.overlay.querySelector('.unit__floor-group').innerHTML = floors

		if (unit[self.mx.type] != "") {
			self.elms.overlay.querySelector('.unit__type').innerHTML = unit[self.mx.type]
		} else if (typeof unit[self.mx.sizeOrchardTerrace] !== "undefined" && unit[self.mx.sizeOrchardTerrace] != "") {
			self.elms.overlay.querySelector('.unit__type').innerHTML = `Orchard + Terrace: ${unit[self.mx.sizeOrchardTerrace]} sq ft`
		} else {
			self.elms.overlay.querySelector('.unit__type').innerHTML = ""
		}
		// self.elms.overlay.querySelector('.unit__type').innerHTML = unit[self.mx.type]


		if (unit[self.mx.totalLiving] != "") {
			self.elms.overlay.querySelector('.unit__total-area').innerHTML = `Total Area: ${unit[self.mx.totalLiving]} sq ft`
		} else if (typeof unit[self.mx.sizeOffice] !== "undefined" && unit[self.mx.sizeOffice] != "") {
			if (unit[self.mx.planType] == "Retail") {
				self.elms.overlay.querySelector('.unit__total-area').innerHTML = `Retail at Entrance: ${unit[self.mx.sizeOffice]} sq ft`
			} else {
				self.elms.overlay.querySelector('.unit__total-area').innerHTML = `Office: ${unit[self.mx.sizeOffice]} sq ft`
			}
		} else {
			self.elms.overlay.querySelector('.unit__total-area').innerHTML = ""
		}
		// self.elms.overlay.querySelector('.unit__total-area').innerHTML = `Total Area: ${unit[self.mx.totalLiving]} sq ft`


		self.elms.overlay.querySelector('.unit__interior-size').innerHTML = `Interior: ${unit[self.mx.sizeInterior]} sq ft`
		self.elms.overlay.querySelector('.unit__exterior-size').innerHTML = `Exterior: ${unit[self.mx.sizeExterior]} sq ft`
		self.elms.overlay.querySelector('.btn--download').href = `${self.assetsDir}/pdfs/${filename}.pdf`
		self.elms.overlay.querySelector('.unit__floorplate').src = `${self.assetsDir}/plates/${filename}.svg`
		self.elms.overlay.querySelector('.overlay__plans--images img').src = `${self.assetsDir}/plans/${filename}.png`
		self.elms.overlay.querySelector('.view-img').style.backgroundImage = `url(${self.assetsDir}/views/${unit[self.mx.viewImg]}.jpg)`
		self.elms.overlay.querySelector('.view-img').style.backgroundPosition = `${viewOffset}px center`
		self.elms.overlay.querySelector('.view-img').dataset.direction = unit[self.mx.dir]
		self.elms.overlay.querySelector('.acc_pdfs').dataset.floors = floors
		self.elms.overlay.querySelector('.acc_pdfs').dataset.unit = unitNumber

		// console.log(floors, unitNumber);

		if (unit[self.mx.planType] == "Podium" || unit[self.mx.planType] == "Retail") {
			self.elms.overlay.querySelector('.overlay__plans--views').style.display = "none"
			self.elms.overlay.querySelector('.unit__number').style.display = "none"
			self.elms.overlay.querySelector('.overlay__plans--materials').style.display = "none"
			self.elms.overlay.querySelector('.overlay__plans--renderings').style.display = "none"
			self.elms.overlay.querySelector('.unit__interior-size').style.display = "none"
			self.elms.overlay.querySelector('.unit__exterior-size').style.display = "none"
		} else {
			self.elms.overlay.querySelector('.overlay__plans--views').style.display = "block"
			self.elms.overlay.querySelector('.unit__number').style.display = "block"
			self.elms.overlay.querySelector('.overlay__plans--materials').style.display = "block"
			self.elms.overlay.querySelector('.overlay__plans--renderings').style.display = "block"
			self.elms.overlay.querySelector('.unit__interior-size').style.display = "block"
			self.elms.overlay.querySelector('.unit__exterior-size').style.display = "block"
		}

		// View Selector
		self.currentViewGroup = self.viewGroups.filter(group => group.name === floors)
		self.currentViewFloor = 0
		self.setViewSelectorLabel()

		// Show the Overlay
		self.elms.overlay.classList.add('is-active')
		document.documentElement.classList.add('no-scroll')
	},

	closeOverlay: () => {
		self.elms.overlay.classList.remove('is-active')
		document.documentElement.classList.remove('no-scroll')
		self.elms.viewToggleAlt.removeAttribute('data-active')
		self.duskViewIsActive = false
	},

	switchView: e => {
		const button = e.currentTarget
		const direction = button.className.replace('btn--', '')

		if (direction === 'up') {
			if (self.currentViewGroup[0].floors[self.currentViewFloor + 1]) {
				self.currentViewFloor += 1
			} else {
				self.currentViewFloor = 0
			}
		} else {
			if (self.currentViewGroup[0].floors[self.currentViewFloor - 1]) {
				self.currentViewFloor -= 1
			} else {
				self.currentViewFloor = self.currentViewGroup[0].floors.length - 1
			}
		}

		self.setViewSelectorLabel()

		const currentViewFloor = self.currentViewGroup[0].floors[self.currentViewFloor]
		const hasDuskView = self.currentViewGroup[0].dusk ? self.currentViewGroup[0].dusk.indexOf(currentViewFloor) !== -1 : false

		if (hasDuskView) {
			self.elms.viewToggleAlt.setAttribute('data-active', '')
			self.elms.viewToggleAltBtns[0].setAttribute('data-active', '')
			self.elms.viewToggleAltBtns[1].removeAttribute('data-active')
		} else {
			self.elms.viewToggleAlt.removeAttribute('data-active')
		}

		self.elms.overlay.querySelector('.view-img').style.backgroundImage = `url(${self.assetsDir}/views/floor${currentViewFloor}.jpg)`

	},

	toggleViewAlt: e => {
		const button = e.currentTarget
		const type = button.innerText.toLowerCase()
		const currentFloor = self.currentViewGroup[0].floors[self.currentViewFloor]
		const file = type === 'dusk' ? `${currentFloor}-Dusk` : currentFloor

		//
		self.elms.viewToggleAlt.querySelector('span[data-active]').removeAttribute('data-active')
		e.currentTarget.setAttribute('data-active', '')

		//
		self.elms.overlay.querySelector('.view-img').style.backgroundImage = `url(${self.assetsDir}/views/floor${file}.jpg)`

		self.duskViewIsActive = type === 'dusk'

		self.onResize()

	},

	setViewSelectorLabel: () => {
		const currentViewFloor = self.currentViewGroup[0].floors[self.currentViewFloor]
		self.elms.viewSelectorLabel.innerHTML = `Floor ${currentViewFloor}`.replace('-', ' ')
	},










	// Events
	// -------------------------------
	onResize: e => {
		const viewImg = self.elms.overlay.querySelector('.view-img')
		const viewOffset = self.getViewOffset(viewImg.dataset.direction)

		return viewImg.style.backgroundPosition = `${viewOffset}px center`
	},









	// Helpers
	// -------------------------------

	setFloorGroupsUnits: () => {
		self.data.forEach(unit => {
			switch (unit[self.mx.level]) {
				case 9:
					self.floorGroups[0].units.push(unit)
					break
				case 29:
					self.floorGroups[1].units.push(unit)
					break
				case 43:
					self.floorGroups[2].units.push(unit)
					break
				case 45:
					self.floorGroups[3].units.push(unit)
					break
			}
		})
	},

	getParentNodeWith: ({ cls, from: el }) => {
		while (el.parentNode) {
			if (el.parentNode.classList.contains(cls)) return el.parentNode
			el = el.parentNode
		}
		return null
	},

	getFileNameFrom: unit => {
		let filename = ''
		let unitNumber = `${unit[self.mx.unit]}`.replace("X", "")
		unitNumber = parseInt(unitNumber)

		if (unit[self.mx.level] === 9) {
			filename = `9-28-`
		} else if (unit[self.mx.level] === 29) {
			filename = `29-42-`
		} else if (unit[self.mx.level] === 43) {
			filename = `43-44-`
		} else {
			filename = `${unit[self.mx.level]}-`
		}

		if (unitNumber < 10) unitNumber = `0${unitNumber}`
		filename += `${unitNumber}`

		return filename
	},

	getUnitWith: unitNumber => {
		let obj
		unitNumber = parseInt(unitNumber)

		for (let unit of self.data) {
			if (unit[self.mx.number] === unitNumber) {
				obj = unit
				break
			}
		}

		return obj
	},

	getViewOffset: direction => {
		let pos = 0

		switch (direction) {
			case 'ENE':
				pos = self.duskViewIsActive ? 0 : 0
				break;
			case 'E':
				pos = self.duskViewIsActive ? -1070 : -600
				break;
			case 'ESE':
				pos = self.duskViewIsActive ? 0 : 0
				break;
			case 'SE':
				pos = self.duskViewIsActive ? -1500 : -1000
				break;
			case 'SSE':
				pos = self.duskViewIsActive ? 0 : 0
				break;
			case 'S':
				pos = self.duskViewIsActive ? -2001.05 : -1500
				break;
			case 'SSW':
				pos = self.duskViewIsActive ? -1650.64 : -1650.64
				break;
			case 'SW':
				pos = self.duskViewIsActive ? -2276.82 : -1852.77
				break;
			case 'WSW':
				pos = self.duskViewIsActive ? 904.36 : 1242.59
				break;
			case 'W':
				pos = self.duskViewIsActive ? -2750 : -2269
				break;
			case 'WNW':
				pos = self.duskViewIsActive ? 0 : 0
				break;
			case 'NW':
				pos = self.duskViewIsActive ? -3200 : -2662.94
				break;
			case 'NWN':
				pos = self.duskViewIsActive ? -3573.09 : -2920.26
				break;
			case 'N':
				pos = self.duskViewIsActive ? -3689.2 : -3122.38
				break;
			case 'NNE':
				pos = self.duskViewIsActive ? -500 : -26.191
				break;
			case 'NE':
				pos = self.duskViewIsActive ? -768.159 : -262.352
				break;
		}

		return pos + (window.innerWidth / 2)
	}


}


export default self
