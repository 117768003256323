'use strict'

import st from './scrollTo'

const self = {
  container : document.querySelector('.scrollnav.show'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.links = self.container.querySelectorAll('button')
  },

  registerEvents : () => {
    self.links.forEach( link => link.addEventListener('click', self.onClick) )
  },

  onClick : e => {
    const button = e.currentTarget
    const id = button.getAttribute("data-id")
    const targetContent = document.getElementById(id)
    const pos = (id === 'architecture' || id === 'models' || id === 'westbank') ? 0 : targetContent.offsetTop

    st.animate(pos, 2000)
  }

}

export default self
