'use strict'

const self = {
  container : document.querySelector('.seattle-map'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.nav = self.container.querySelector('nav')
    self.videos = self.container.querySelectorAll('video')
  },

  registerEvents: () => {
    self.nav.addEventListener('click', self.changeVideo)
    window.addEventListener('scroll', self.onScroll)
  },

  changeVideo : e => {
    const label = e.target.nodeName === 'LABEL' ? e.target : null
    if ( !label ) return false

    const index = label.getAttribute('data-index')
    const activeLabel = self.container.querySelector('nav label.is-active');
    if ( activeLabel ==  label ) return false

    const activeVideoContainer = self.container.querySelector('.videos figure.is-active')
    const targetVideoContainer = self.container.querySelector(`.videos figure[data-index="${index}"]`)

    // Manage label classes
    if ( activeLabel ) activeLabel.classList.remove('is-active')
    label.classList.add('is-active')

    // Manage video classes
    if ( activeVideoContainer ) activeVideoContainer.classList.remove('is-active')
    targetVideoContainer.classList.add('is-active')

    // Play video
    targetVideoContainer.querySelector('video').play()

    // Reset last video
    if ( activeVideoContainer ) self.resetVideo( activeVideoContainer.querySelector('video') )
  },

  resetVideo : video => {
    video.pause();
    video.currentTime = 0;
  },

  onScroll : e => {
    const containerIsInView = self.container.getBoundingClientRect().top - (window.innerWidth / 2) < 0

    if ( containerIsInView ) self.initMap()
  },

  initMap : () => {
    self.nav.querySelector('label').click()
    window.removeEventListener('scroll', self.onScroll)
  }

}

export default self
