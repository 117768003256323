'use strict'

import HomeCover from '../modules/homeCover'

export default class Slider {
  constructor (el, args) {
    this.container          = el
    this.slideContainer     = this.container.querySelector('.slider--slides')
    this.slides             = this.container.querySelectorAll(".slider--slide")
    this.buttons            = this.container.querySelectorAll('.slider--btn-next, .slider--btn-prev');

    this.args = {
      rotate              : (args.rotate) ? true : false,
      intervalDuration    : (args.intervalDuration) ? args.intervalDuration : 7000,
      transitionDuration  : (args.transitionDuration) ? args.transitionDuration : 1500
    }

    this.setup()
    this.setupProgress()
  }

  setup () {
    this.currentEL = this.slides[0]
    this.currentEL.classList.add("show")
    this.checkForAnimation()

    if (this.buttons) {
      Array.from(this.buttons).forEach( btn => {
          let dir = (btn.classList.contains("slider--btn-prev")) ? "prev" : "next"
          btn.addEventListener("click", () => this.cycle(dir, true))
      })
    }

    if (this.args.rotate) this.toggleRotation()

    this.container.setAttribute('data-loaded', 'true')

    window.addEventListener('resize', this.onResize.bind(this))
  }

  // Actions
  // ---------------------
  toggleRotation () {
    if (!this.rotateInterval) {
      this.rotateInterval = setInterval( () => this.cycle("next"), this.args.intervalDuration)
    } else {
      clearInterval(this.rotateInterval)
      this.rotateInterval = null
    }
  }

  cycle (dir = "next", btn = false) {
    let nextEl, prevEl, outEl

    if (btn) this.toggleRotation()

    switch (dir) {
      case "next" :
        nextEl = (this.currentEL.nextElementSibling) ? this.currentEL.nextElementSibling : this.slides[0]
      break;
      case "prev" :
        nextEl = (this.currentEL.previousElementSibling) ? this.currentEL.previousElementSibling : this.slides[this.slides.length-1]
      break;
    }

    // Handle Out Element
    outEl = this.currentEL
    //outEl.classList.add("out")
    outEl.classList.remove("show")
    //setTimeout( () => outEl.classList.remove("out"), this.args.transitionDuration )

    // Handle Next Element
    this.currentEL = nextEl
    this.currentEL.classList.add("show")

    //
    this.container.setAttribute('data-current', nextEl.getAttribute('data-index'))

    //
    this.checkForAnimation()

    //
    if (btn) this.toggleRotation()

  }

  checkForAnimation () {
    const attr = this.currentEL.getAttribute('data-animated')
    if ( attr ) HomeCover.playSliderAnimation(attr)
  }

  onResize (e) {
    this.setupProgress()
  }




  // Progress
  // ------------------------
  setupProgress () {
    this.progressContainer = this.container.querySelector('.slider--progress')
    this.progressCurrent = this.progressContainer.querySelector('.slider--progress--current')
    this.progressContainerWidth = this.progressContainer.clientWidth
    this.progressCurrentWidth = this.progressCurrent.clientWidth
  }
}
