'use strict'

import PanoViewer from '../classes/PanoViewer'

const self = {
  container : document.querySelector('.index-views'),
  overlay : document.querySelector('.overlay__index-views'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.thumbnails = [...self.container.querySelectorAll('.thumbnails button')]
    self.btnClose = self.overlay.querySelector('.btn--close')
    self.viewImg = self.overlay.querySelector('.view-img')
  },

  registerEvents : () => {
    self.thumbnails.map( button => button.addEventListener('click', self.openOverlay) )
    self.btnClose.addEventListener('click', self.closeOverlay)
    const panoViewer = new PanoViewer({ el : self.viewImg })
  },

  openOverlay : e => {
    const button = e.currentTarget
    const viewImgContainer = self.overlay.querySelector('.view-img')

    viewImgContainer.style.backgroundImage = self.getViewImageFrom(button)
    self.overlay.dataset.active = 'true'
  },

  closeOverlay : e => {
    self.overlay.removeAttribute('data-active')
    self.viewImg.style.backgroundImage = `url('')`
  },

  getViewImageFrom : button => {
    const img = button.children[0].src

    return `url(${img})`
  }

}

export default self
