'use strict'

import LoopSlider from '../classes/LoopSlider'

const self = {
  container : document.querySelector('.home--cover'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new LoopSlider(self.container, {
      intervalDuration : 6000
    })
  },

}

export default self
