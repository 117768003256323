'use strict'

import st from './scrollTo'

const self = {
  container : document.querySelector('footer'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.btnBackToTop = self.container.querySelector('.btn--back-to-top')
  },

  registerEvents : () => {
    self.btnBackToTop.addEventListener('click', e => st.animate(0,2000) )
  }

}

export default self
