
export default class LerpTransform {
  constructor (args) {

    // Set all variables
    this.el = args.el
    this.dis = args.distance || window.innerHeight
    this.min = args.min
    this.max = args.max
    this.val = args.initalValue || args.min

    // Watch scroll
    window.addEventListener('scroll', this.onScroll.bind(this))

  }

  onScroll (e) {
  	if(window.pageYOffset >= this.dis) return false

    // Uses linear interpolation
    // https://en.wikipedia.org/wiki/Linear_interpolation
  	this.val = this.min + (this.max - this.min) * Math.min(window.pageYOffset / this.dis, 1)

    // Apply new transform value
    this.el.style.opacity = 1 - this.val

  }

}
