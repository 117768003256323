'use strict'

import BodyMovin from '../classes/bodymovin'
import LerpTransform from './lerpTransform'
import buildingAnimation from '../animations/building_1500x1740 w_line_c_cascade_alpha.json'

const self = {
  container : document.querySelector('.home--cover') || document.querySelector('.splash-cover'),

  init : () => {

    // if ( !document.querySelector('.splash-cover') ) {
    //   self.setupElements()
    // }

    self.registerEvents()
    self.setupBuildingAnimation()

    if ( document.querySelector('.splash-cover') ) {
      self.playSliderAnimation('building')
    }

  },

  setupElements : () => {
    new LerpTransform({
      el       : self.container,
      min      : 0,
      max      : 1,
      distance : window.innerHeight - 300
    })
  },

  registerEvents : () => {
    self.buildingAnimContainer = document.querySelector('[data-animated="building"] .bodymovin-container')
  },

  playSliderAnimation : attr => {
    switch (attr) {
      case 'building' :
        return self.bmBuildingAnimation.anim.goToAndPlay(0)
      break;
    }
  },

  setupBuildingAnimation : () => self.bmBuildingAnimation = new BodyMovin({
    elm : self.buildingAnimContainer,
    data : buildingAnimation
  })

}

export default self
