'use strict'

const self = {
  container : document.getElementById('contact-form'),
  dpsArr : [],

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.realtorToggles = [...self.container.querySelectorAll('input[name="Are_you_a_realtor__c"]')]
    self.workingToggles = [...self.container.querySelectorAll('input[name="Cumulus1__Working_with_Other_Realtors__c_toggle"]')]
    self.requiredInputs = [...self.container.querySelectorAll('input.required')]
    self.requiredCheckboxes = [...self.container.querySelectorAll('.checkbox-group.required input')]
    self.dspCheckboxes = [...self.container.querySelectorAll('input[type="checkbox"][name="dps"]')]
    self.optIn = document.getElementById('optin')
  },

  registerEvents : () => {
      self.realtorToggles.forEach( input => input.addEventListener('change', self.toggleHiddenInputs) )
      self.workingToggles.forEach( input => input.addEventListener('change', self.toggleHiddenInputs) )
      self.dspCheckboxes.forEach( input => input.addEventListener('click', self.setDspInput) )
      //self.container.addEventListener('submit', self.onSubmit)
  },

  setDspInput : e => {
    const input = e.currentTarget
    const isChecked = input.checked

    if (isChecked) {
      self.dpsArr.push(input.value)
    } else {
      self.dpsArr.splice( self.dpsArr.indexOf(input.value), 1 )
    }

    return document.querySelector('input[name="Desired_Property_Size__c"]').value = self.dpsArr.join(';')
  },


  toggleHiddenInputs : e => {
    const input = e.currentTarget
    const { name, value } = input
    const siblingContainer = input.parentNode.parentNode.querySelector(`[data-trigger-name="${name}"]`)

    if ( value === 'No' ) {
      siblingContainer.classList.add('hidden');
    } else if ( value === 'Yes' ) {
      siblingContainer.classList.remove('hidden');
    }
  },

  onSubmit : e => {
    let action = ''
    let formIsValid = true

    self.requiredInputs.map( input => {
      switch (input.type) {
        case 'text':
          if ( input.value.length === 0 ) formIsValid = false
          action = ( !formIsValid ) ? 'add' : 'remove'
          input.parentNode.classList[action]('error')
        break;
        case 'email':
          const isValid = self.isValid({ email : input.value })
          if ( isValid === false ) formIsValid = false
          action = ( !formIsValid ) ? 'add' : 'remove'
          input.parentNode.classList[action]('error')
        break;
      }
    })
    console.log('after inputs', formIsValid);

    let hasChecked = false
    self.requiredCheckboxes.map( input => {
      if ( input.checked ) hasChecked = true
    })
    console.log('after checkboxes', formIsValid);

    if ( !hasChecked ) {
      formIsValid = false
      self.requiredCheckboxes[0].parentNode.classList.add("error")
    } else {
      self.requiredCheckboxes[0].parentNode.classList.remove("error")
    }

    //
    if ( !self.optIn.checked ) {
      formIsValid = false
      self.optIn.parentNode.classList.add('error')
    } else {
      self.optIn.parentNode.classList.remove('error')
    }
    console.log('after optin', formIsValid);
    if ( !formIsValid ) e.preventDefault()
  },

  isValid : ({ email }) => {

    // Validate Email
    if ( email ) {
      const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      const sanitizedEmail = email.toLowerCase()
      const isEmailValid = re.test(sanitizedEmail)
      return isEmailValid
    }

  }
}

export default self
