'use strict'

import AutoPan from './autoPan'

const self = {
  container : document.querySelector('.page-workspace'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new AutoPan({
      el       : document.querySelectorAll('.workspace--mid--image'),
    })
    new AutoPan({
      el       : document.querySelectorAll('.workspace--amenities--image'),
    })
    new AutoPan({
      el       : document.querySelector('.sustainability-image'),
    })
  },

}

export default self
