
export default class AutoPanForTeam {
  constructor (args) {

    // Set all variables
    this.el = args.el

    // Watch scroll
    window.addEventListener('scroll', this.onScroll.bind(this))

  }

  onScroll(e) {

    if (this.el.getBoundingClientRect().top < window.innerHeight - 200) {
      this.el.classList.add('show')

    }

  }

}
