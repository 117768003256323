'use strict'

import BodyMovin from '../classes/bodymovin'
import Lerp from '../classes/Lerp'

import pigAnimation from '../../cny/data'
import circleAnimation from '../../cny/Veil_v01'

const self = {
  container : document.querySelector('.page__cny2019'),

  init : () => {
    self.initLerp()
    self.initBodyMovin()
    setTimeout( () => document.querySelector('.page__cny2019--cover').setAttribute('data-loading', 'false'), 3000)

    //
    //const alertContainer = self.container.querySelector('.page__cny2019--alert')
    //alertContainer.querySelector('button').addEventListener('click', () => alertContainer.classList.add('hide'))
  },

  initLerp : () => {
    const lerpElms = [...document.querySelectorAll('.lerp')]

    lerpElms.map( el => {
      const { max } = el.dataset

      return new Lerp({
        el : el,
        min : 0,
        max : max
      })
    })
  },

  initBodyMovin : () => {
    self.bmPigAnimation = new BodyMovin({
      elm  : document.querySelector(`.page__cny2019--cover .pig-animation`),
      data : pigAnimation,
      autoplay : true,
      loop : true
    })

    self.bmPigAnimation = new BodyMovin({
      elm  : document.querySelector(`.page__cny2019--cover .circle`),
      data : circleAnimation,
      autoplay : true,
      loop : true
    })
  }

}

export default self
