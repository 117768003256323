'use strict'

const self = {
  container : document.querySelector('.home--cover'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.homeCover = document.querySelector('.home--cover')
    self.header = document.querySelector(".header");
  },

  registerEvents : () => {
    window.addEventListener('scroll', self.onScroll)
  },

  onScroll : e => {
    const heroHeight = self.homeCover.clientHeight;
    const headerRect = self.header.getBoundingClientRect();

    if (heroHeight < window.scrollY + headerRect.bottom) {
      self.header.classList.remove("header-transparent");
    } else {
      self.header.classList.add("header-transparent");
    }
  }
}

export default self