'use strict'

const self = {
  container : document.querySelector('.inline-video-player'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.bgImage = self.container.querySelector('.bg-image')
    self.video = self.container.querySelector('video')
    self.btnPlayVideo = document.querySelector('.btn-play-video')
  },

  registerEvents : () => {
    if ( self.video ) {
      self.bgImage.addEventListener('click', self.toggleVideo)
      self.video.addEventListener('pause', self.toggleVideo)
      self.video.addEventListener('ended', self.toggleVideo)
    }

    if ( self.btnPlayVideo ) {
      self.btnPlayVideo.addEventListener('click', () => self.bgImage.click() )
    }
  },

  toggleVideo : e => {
    const target = e.currentTarget
    const action = (target === self.bgImage) ? 'play' : 'pause'

    if ( action === 'play' ) {
      self.video.play()
      self.container.classList.add('fade-cover')
      setTimeout( () => self.container.classList.add('is-playing'), 1000)
    } else if ( action === 'pause' ) {
      self.video.pause()
      self.container.classList.remove('is-playing')
      self.container.classList.remove('fade-cover')
    }

  }

}

export default self
