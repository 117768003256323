'use strict'

export default class Slider {
  constructor (el, args) {
    this.container          = el
    this.slides             = this.container.querySelectorAll(".slick-slide")
    this.buttons            = this.container.querySelectorAll('.slider--btn-next, .slider--btn-prev');

    this.args = {
      intervalDuration    : (args.intervalDuration) ? args.intervalDuration : 7000
    }

    this.setup()
  }

  setup () {
    this.currentEL = this.slides[0]
    this.currentEL.classList.add("slick-active")

    if (this.buttons) {
      Array.from(this.buttons).forEach( btn => {
        let dir = (btn.classList.contains("slider--btn-prev")) ? "prev" : "next"
        btn.addEventListener("click", () => this.cycle(dir, true))
      })
    }

    this.toggleRotation()

    window.addEventListener('resize', this.onResize.bind(this))
  }


  // Actions
  // ---------------------
  toggleRotation () {
    if (!this.rotateInterval) {
      this.rotateInterval = setInterval( () => this.cycle("next"), this.args.intervalDuration)
    } else {
      clearInterval(this.rotateInterval)
      this.rotateInterval = null
    }
  }

  cycle (dir = "next", btn = false) {
    let nextEl, outEl

    if (btn) this.toggleRotation()

    switch (dir) {
      case "next" :
        nextEl = (this.currentEL.nextElementSibling) ? this.currentEL.nextElementSibling : this.slides[0]
      break;
      case "prev" :
        nextEl = (this.currentEL.previousElementSibling) ? this.currentEL.previousElementSibling : this.slides[this.slides.length-1]
      break;
    }

    // Handle Out Element
    outEl = this.currentEL
    outEl.classList.remove("slick-active")

    // Handle Next Element
    this.currentEL = nextEl
    this.currentEL.classList.add("slick-active")

    //
    if (btn) this.toggleRotation()
  }

  onResize (e) {
  }

}
