
const self = {

  animate: (to, duration) => {
    self.to           = to
    self.duration     = duration
    self.start        = document.documentElement.scrollTop + document.body.scrollTop
    self.change       = self.to - self.start
    self.fps          = 60
    self.elapsedTime  = 0

    return window.requestAnimationFrame(self.loop)
  },

  loop : () => {
    const position = self.easeInOut(self.elapsedTime, self.start, self.change, self.duration)

    document.documentElement.scrollTop = position
    document.body.scrollTop = position

    if (self.elapsedTime < self.duration) {
      self.elapsedTime += self.fps
      window.requestAnimationFrame(self.loop)
    }
  },

  easeInOut : (currentTime, start, change, duration) => {
    currentTime /= duration / 2

    if (currentTime < 1) return change / 2 * currentTime * currentTime + start

    currentTime -= 1

    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
  }

}

export default self
