'use strict'

import AutoPanForTeam from './autoPanForTeam'

const self = {
  container : document.querySelector('.page-team'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new AutoPanForTeam({
      el       : document.querySelector('#JohnHogan'),
    })
    new AutoPanForTeam({
      el       : document.querySelector('#Seattle_First-Light_Building-Architecture-Model_1'),
    })
    new AutoPanForTeam({
      el       : document.querySelector('#IMG-7'),
    })
  },

}

export default self
