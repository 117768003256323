'use strict'

const self = {
  container : document.querySelector('.gallery-overlay'),
  currentIndex : 0,

  init : () => {
    self.setupElements()
    self.registerEvents()
    self.setupGalleryImages()
  },

  setupElements : () => {
    self.pageImages = [...document.querySelectorAll('[data-gallery-image]')]
    self.galleryImageContainer = self.container.querySelector('.images')
    self.btnClose = self.container.querySelector('.btn--close')
    self.btnNext = self.container.querySelector('.btn--next')
    self.btnPrev = self.container.querySelector('.btn--prev')
  },

  registerEvents : () => {
    self.pageImages.forEach( image => image.addEventListener('click', self.showGallery) )
    self.btnClose.addEventListener('click', self.closeGallery)
    self.btnNext.addEventListener('click', self.next)
    self.btnPrev.addEventListener('click', self.prev)
  },

  setupGalleryImages : () => self.pageImages.forEach( (pageImage, i) => {
    const figure = document.createElement('figure')
          figure.setAttribute("data-gallery-index", i)

    const galleryImage = new Image()
          galleryImage.src = pageImage.src

    const caption = document.createElement('p')
          caption.className = "caption"
          caption.innerHTML = pageImage.getAttribute('alt')

    pageImage.setAttribute("data-gallery-index", i)

    figure.appendChild(galleryImage)
    figure.appendChild(caption)
    self.galleryImageContainer.appendChild(figure)
  }),

  showGallery : e => {
    const trigger = e.currentTarget
    const index = trigger.getAttribute("data-gallery-index")
    const galleryImageContainer = self.galleryImageContainer.querySelector(`[data-gallery-index="${index}"]`)

    self.currentIndex = parseInt(index)

    self.container.classList.add('show')
    galleryImageContainer.classList.add('show')
  },

  closeGallery : e => {
    self.container.classList.remove('show')
    self.galleryImageContainer.querySelector(`figure.show`).classList.remove('show')
  },

  next : e => {
    self.hideCurrentImage()
    self.currentIndex += 1

    const nextRequestedImage = self.galleryImageContainer.querySelector(`[data-gallery-index="${self.currentIndex}"]`)

    if ( !nextRequestedImage ) self.currentIndex = 0

    const image = ( nextRequestedImage )
      ? nextRequestedImage
      : self.galleryImageContainer.querySelector(`[data-gallery-index="${self.currentIndex}"]`)

    image.classList.add('show')

    console.log(self.currentIndex)
  },

  prev : e => {
    self.hideCurrentImage()
    self.currentIndex = ( self.currentIndex === 0 ) ? self.pageImages.length - 1 : self.currentIndex - 1
    self.galleryImageContainer.querySelector(`[data-gallery-index="${self.currentIndex}"]`).classList.add('show')

    console.log(self.currentIndex)
  },

  hideCurrentImage : () => self.galleryImageContainer.querySelector(`figure.show`).classList.remove('show')

}

export default self
