'use strict'

const self = {
  container : document.querySelector('.home--cover'),
  cursorFlgHero : true,
  cursorFlgHeader : false,

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.homeCover = document.querySelector('.home--cover')
    self.headerTransparent = document.querySelector('.header-transparent')
    self.mouseStalker = document.querySelector('.mouse-stalker')
    self.mouseStalkerCircle = document.querySelector('.mouse-circle')
    self.prevIcon = document.querySelector('#prevIcon')
    self.nextIcon = document.querySelector('#nextIcon')
  },

  registerEvents: () => {
    window.addEventListener('mousemove', self.onMouseMove)
    self.homeCover.addEventListener('mouseenter', self.homeCoverEnter)
    self.homeCover.addEventListener('mouseleave', self.homeCoverLeave)
    self.headerTransparent.addEventListener('mouseleave', self.headerTransparentLeave)
    self.headerTransparent.addEventListener('mouseenter', self.headerTransparentEnter)
  },

  onMouseMove: e => {
    const heroWidth = self.homeCover.clientWidth

    if (!self.cursorFlgHeader && !self.cursorFlgHero) {
      self.mouseStalker.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)'
      self.mouseStalkerCircle.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)'
      if (e.clientX < heroWidth / 2) {
        self.mouseStalkerCircle.classList.remove('hide')
        self.prevIcon.classList.remove('hide')
        self.nextIcon.classList.add('hide')
      } else {
        self.mouseStalkerCircle.classList.remove('hide')
        self.prevIcon.classList.add('hide')
        self.nextIcon.classList.remove('hide')
      }
    }
  },

  homeCoverEnter: e => self.cursorFlgHero = false,

  homeCoverLeave: e => {
    self.mouseStalkerCircle.classList.add('hide')
    self.prevIcon.classList.add('hide')
    self.nextIcon.classList.add('hide')
    self.cursorFlgHero = true
  },

  headerTransparentLeave: e => self.cursorFlgHeader = false,

  headerTransparentEnter: e => {
    self.mouseStalkerCircle.classList.add('hide')
    self.prevIcon.classList.add('hide')
    self.nextIcon.classList.add('hide')
    self.cursorFlgHeader = true
  },
}

export default self