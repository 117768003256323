'use strict'

import BodyMovin from '../classes/bodymovin'
import LerpTransform from './lerpTransform'
import animation from '../animations/building_1500x1740 w_line_c_cascade.json'

const self = {
  container : document.querySelector('main.home .gallery'),
  hasBeenTriggered : false,

  init : () => {
    self.setupElements()
    self.registerEvents()
    self.setupBodyMovin()

    const panelContainerTransform = new LerpTransform({
      el       : self.panelContainer,
      min      : 1,
      max      : 0,
      distance : window.innerHeight - 300
    })

    const sectionTitleTransform = new LerpTransform({
      el       : self.sectionTitleContainer,
      min      : 0,
      max      : 1,
      distance : window.innerHeight - 500
    })
  },

  setupElements : () => {
    self.buttons = [...self.container.querySelectorAll('button')]
    self.figureImage = self.container.querySelector('figure img')
    self.caption = self.container.querySelector('span.caption')
    self.animationContainer = self.container.querySelector('.animation--container')
    self.panelContainer = self.container.querySelector('.panel--container')
    self.sectionTitleContainer = self.container.querySelector('.section--title-wrapper')
  },

  registerEvents : () => {
    self.buttons.forEach( button => button.addEventListener('click', self.changeImage) )
    window.addEventListener('scroll', self.onScroll)
  },

  setupBodyMovin : () => {
    self.bmAnimation = new BodyMovin({
      elm : self.animationContainer,
      data : animation,
      onComplete : self.changePanels
    })

  },

  changeImage : e => {
    const button = e.currentTarget
    const caption = button.getAttribute('data-caption')
    const bgImage = button.querySelector('.bg-image')
    const src = bgImage.style.backgroundImage.match(/\((.*?)\)/)[1].replace(/('|")/g,'')

    button.parentNode.querySelector('button.active').classList.remove("active")
    button.classList.add('active')

    self.figureImage.src = src
    self.caption.innerHTML = caption
  },

  onScroll : e => {
    const isInView = self.container.getBoundingClientRect().top - (window.innerHeight / 2) < 0

    if ( isInView && !self.hasBeenTriggered ) {
      self.hasBeenTriggered = true
      self.bmAnimation.play()
    }

  },

  changePanels : e => {
    self.container.querySelector('.panel.animation').classList.remove('show')
    self.container.querySelector('.panel.content').classList.add('show')
  }

}

export default self
