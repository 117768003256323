'use strict'

import AutoPan from './autoPan'

const self = {
  container : document.querySelector('.home--cover'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new AutoPan({
      el       : document.querySelector('#summarize-manifesto-image'),
    })
    new AutoPan({
      el       : document.querySelector('#pan-right-image'),
    })
    new AutoPan({
      el       : document.querySelector('#pan-left-image'),
    })
  },

}

export default self
