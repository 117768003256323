
export default class Lerp {

  constructor (args) {
    this.el = args.el
    this.dis = args.distance || window.innerHeight
    this.min = args.min
    this.max = args.max
    this.val = args.initialValue || args.min

    this.onScroll = this.handleScroll.bind(this)

    this.watch()
  }

  handleScroll (e) {
  	if ( window.pageYOffset >= this.dis ) return false
  	this.val = this.min + (this.max - this.min) * Math.min(window.pageYOffset / this.dis, 1)
  	this.el.style.transform = this.getTransformValue(this.val)
  }

  getTransformValue (v) {
    return `translate3d(0,-${v}%,0)`;
  }

  watch () {
    return window.addEventListener('scroll', this.onScroll)
  }

  destroy () {
    return window.removeEventListener('scroll', this.onScroll)
  }

}
