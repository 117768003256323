
let self

export default self = {
  container : document.getElementById('mc_embed_signup'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.form = document.getElementById('mc-embedded-subscribe-form')
    self.resultsElm = document.querySelector('.results')
    self.resultsSuccessElm = self.resultsElm.querySelector('.results__success')
    self.resultsErrorElm = self.resultsElm.querySelector('.results__error')
    self.btnBack = self.resultsErrorElm.querySelector('.btn-back')
  },

  registerEvents : () => {
    self.form.addEventListener("submit", self.onSubmit)
    self.btnBack.addEventListener("click", () => self.setState("form"))
  },

  onSubmit : e => {
    e.preventDefault()

    if ( !self.formIsValid() ) return false

    const ajaxOpts = self.getAjaxOptions()

    return $.ajax(ajaxOpts)
  },

  onSubmitSuccess : (res) => {
    const { msg, result } = res

    self.setState(result)

    if (result != "success") {
      self.resultsErrorElm.querySelector('.msg').innerHTML = msg
      return false
    }

    // NOTE: success message is hardcoded in the template
    // return self.resultsSuccessElm.querySelector('.msg').innerHTML = msg
  },

  getAjaxOptions : () => {
    return {
      url: self.form.getAttribute('action'),
      type: self.form.getAttribute('method'),
      data: $(self.form).serialize(),
      dataType: 'jsonp',
      contentType: "application/json; charset=utf-8",
      success: self.onSubmitSuccess
    }
  },

  formIsValid : () => {
    let formIsValid = true
    const textInputs = [...self.container.querySelectorAll('input[type="text"]')]
    const emailInput = self.container.querySelector('input[type="email"]')
    const optinInput = self.container.querySelector('input[type="checkbox"]')

    textInputs.map( input => {
      if ( input.value.length === 0 ) {
        formIsValid = false
        input.parentNode.classList.add("error")
      } else {
        input.parentNode.classList.remove("error")
      }
    })

    if ( emailInput.value.length === 0 ) {
      formIsValid = false
      emailInput.parentNode.classList.add("error")
    } else {
      emailInput.parentNode.classList.remove("error")
    }

    if ( !optinInput.checked ) {
      formIsValid = false
      optinInput.parentNode.classList.add("error")
    } else {
      optinInput.parentNode.classList.remove("error")
    }

    return formIsValid
  },

  setState : state => self.container.setAttribute("data-state", state)

}
