'use strict'

import AutoPan from './autoPan'

const self = {
  container : document.querySelector('.page-seattle'),

  init : () => {
    self.setupElements()
  },

  setupElements : () => {
    new AutoPan({
      el       : document.querySelectorAll('.seattle--downtown--image'),
    })
    new AutoPan({
      el       : document.querySelector('#aerial-Looking-North'),
    })
    new AutoPan({
      el       : document.querySelectorAll('.seattle--overlook-walk--image'),
    })
    new AutoPan({
      el       : document.querySelector('#First-Light-Seattle-Map_Distance'),
    })
  },

}

export default self
