'use strict'

const self = {
  container : document.querySelector('.overlay__lny'),

  init : () => {
    self.setupElements()
    self.registerEvents()
    setTimeout( () => self.container.classList.add('show'), 1000)

    self.container.querySelector('.btn__close').addEventListener('click', () => {
      return self.container.classList.remove('show')
    })
  },

  setupElements : () => {

  },

  registerEvents : () => {

  }

}

export default self
